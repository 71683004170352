import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { TheRaceWidget } from './TheRaceWidget'
import { TopPartyResults } from '../data'

export interface TheRaceWidgetRouteProps {
    electionDefinition: ElectionDefinition
    partiesToShow?: number
    providedData?: TopPartyResults
    redirectTo?: string
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const TheRaceWidgetRegistration = createRegisterableComponent(
    'election-the-race-widget',
    (props: TheRaceWidgetRouteProps, { onEvent }) => {
        return <TheRaceWidget {...props} onEvent={onEvent} />
    },
)
