import styled from '@emotion/styled'
import { IconChevronDownTN } from '../../../icons'
import { breakpoint, calcRem } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { placeholderAnimation } from '../components/placeholderAnimation'

export const StyledChevron = styled(IconChevronDownTN)<{ isOpen: boolean }>(
    ({ theme, isOpen }) => {
        return {
            width: calcRem(24),
            height: calcRem(24),
            display: theme.kind === 'thewest' ? 'none' : 'flex',

            rotate: `${isOpen ? -180 : 0}deg`,

            transition: 'rotate 0.25s',
        }
    },
)

export const ElectorateDetailsExpander = styled('div')<{ isOpen: boolean }>(
    ({ isOpen }) => {
        return {
            display: 'grid',
            gridTemplateRows: isOpen ? '1fr' : '0fr',
            overflow: 'hidden',
            width: '100%',

            transition: 'grid-template-rows 0.5s',
        }
    },
)

export const ElectorateDetailsContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
    minHeight: 0,
})

export const FullElectorateContainer = styled('div')(
    ({ isLoading }: { isLoading: boolean }) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: calcRem(124),
        height: isLoading ? calcRem(124) : 'auto',
        padding: isLoading ? 0 : calcRem(16),
        gap: calcRem(24),
        background: tokens.thenightly.colors.palette.white,
        borderRadius: calcRem(12),
        border: `${calcRem(1)} solid ${
            tokens.thenightly.colors.palette.neutral[20]
        }`,
    }),
)

export const FullElectorateHeaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
    [breakpoint('sm')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(28),
        flexDirection: 'row',
        gap: calcRem(32),
        alignItems: 'center',
    },
})

export const FullElectorateCandidateImageContainer = styled('div')<{
    initials?: string
}>(({ initials }) => ({
    position: 'relative',
    width: calcRem(32),
    height: calcRem(32),
    borderRadius: calcRem(60),
    [breakpoint('sm')]: {
        width: calcRem(72),
        height: calcRem(72),
    },
    ...placeholderAnimation,

    '&::after': {
        content: `"${initials}"`,
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: tokens.thewest.colors.palette.greyHippo,
        [breakpoint('sm')]: {
            fontSize: calcRem(32),
        },
    },
}))

export const FullElectorateCandidateImage = styled('img')(() => ({
    position: 'relative',
    zIndex: 5,
    width: calcRem(32),
    height: calcRem(32),
    border: `2px solid ${tokens.thewest.colors.palette.greyMouse}`,
    borderRadius: calcRem(60),
    backgroundRepeat: 'no-repeat',
    [breakpoint('sm')]: {
        width: calcRem(72),
        height: calcRem(72),
    },
}))

export const FullElectorateCandidateNameText = styled('p')({
    margin: 0,
    fontSize: calcRem(12),
    fontWeight: 600,
    lineHeight: calcRem(16),
    letterSpacing: calcRem(-0.4),
    [breakpoint('sm')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(28),
    },
})

export const FullElectorateCandidatesContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
})

export const FullElectorateCandidateContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(16),
})

interface SeatDetailsTextProps {
    variant: 'name' | 'state'
}

export const FullElectorateSeatDetailsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: calcRem(4),

    span: {
        display: 'flex',
        gap: calcRem(4),
    },
})

export const FullElectorateDetailsText = styled('p')<SeatDetailsTextProps>(
    ({ variant }) => ({
        fontSize: calcRem(20),
        fontWeight: variant === 'name' ? 600 : 400,
        color:
            variant === 'name'
                ? tokens.thenightly.colors.palette.neutral[100]
                : tokens.thenightly.colors.palette.neutral[70],
        lineHeight: calcRem(28),
        letterSpacing: calcRem(-0.4),
        margin: 0,
    }),
)

export const FullElectorateStatusText = styled('p')({
    margin: 0,
    fontSize: calcRem(16),
    fontWeight: 400,
    lineHeight: calcRem(24),
    letterSpacing: calcRem(-0.32),
    textTransform: 'uppercase',
    color: tokens.thenightly.colors.palette.neutral[0],
})

export const IncumbentLabel = styled('p')({
    display: 'none',
    margin: 0,
    color: tokens.thenightly.colors.palette.neutral[70],
    fontSize: calcRem(16),
    fontWeight: 400,
    lineHeight: calcRem(24),
    letterSpacing: calcRem(-0.32),
    [breakpoint('sm')]: {
        display: 'block',
    },
})

interface SeatCandidatePartyTextProps {
    color: string
}

export const ElectorateCandidatePartyText = styled(
    'p',
)<SeatCandidatePartyTextProps>(({ color }) => ({
    margin: 0,
    fontSize: calcRem(12),
    fontWeight: 600,
    color,
    lineHeight: calcRem(16),
    display: 'block',
    [breakpoint('sm')]: {
        display: 'none',
    },

    span: {
        fontSize: calcRem(12),
        lineHeight: calcRem(16),
        color: tokens.thenightly.colors.palette.neutral[70],
        fontWeight: 400,
        letterSpacing: calcRem(-0.32),

        [breakpoint('sm')]: {
            display: 'none',
        },
    },
}))

export const FullElectorateSwingContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: calcRem(4),
})

export const FullElectorateSwingLabel = styled('div')({
    fontSize: calcRem(12),
    lineHeight: calcRem(16),
    color: tokens.thenightly.colors.palette.neutral[70],
    display: 'none',
    [breakpoint('sm')]: {
        display: 'block',
    },
})

interface FullElectorateVoteCountTextProps {
    bold?: boolean
}

export const FullElectorateVoteCountText = styled(
    'p',
)<FullElectorateVoteCountTextProps>(({ bold = false }) => ({
    fontSize: calcRem(16),
    fontWeight: bold ? 700 : 500,
    [breakpoint('sm')]: {
        fontSize: calcRem(20),
    },
}))

export const FullElectoratePartyText = styled('p')({
    fontSize: calcRem(20),
    fontWeight: 700,
    display: 'none',
    [breakpoint('sm')]: {
        display: 'block',
    },
})

export const ShowMoreButtonTW = styled('button')(({ theme }) => ({
    background: 'transparent',
    padding: calcRem(7, 8),
    borderRadius: calcRem(8),
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(13),
    fontWeight: 700,
    cursor: 'pointer',
    border: 0,
    alignSelf: 'center',
    color: tokens.thewest.colors.palette.silver,

    '&:focus': {
        outline: 'none',
        backgroundColor: tokens.thewest.colors.palette.greyDolphin,
    },

    '&:hover': {
        backgroundColor: tokens.thewest.colors.palette.greyErmine,
    },

    '&:disabled': {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        opacity: 0.5,
    },
}))

interface CandidateTppTextProps {
    /* Whether this element is visible at desktop, or on mobile */
    variant: 'mobile' | 'desktop'
}

export const FullElectorateCandidateTppText = styled(
    'p',
)<CandidateTppTextProps>(({ variant }) => ({
    position: variant === 'desktop' ? 'absolute' : 'relative',
    display: variant === 'desktop' ? 'none' : 'block',
    left: '50%',
    transform: 'translateX(-50%)',

    fontSize: calcRem(14),
    fontWeight: 500,
    color: tokens.thenightly.colors.palette.neutral[70],
    margin: 0,

    [breakpoint('sm')]: {
        fontSize: calcRem(16),
        display: variant === 'desktop' ? 'block' : 'none',
    },
}))
