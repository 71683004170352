import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../../../__styling'
import { placeholderAnimation } from '../../../components/placeholderAnimation'

export const PlaceholderContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',

    width: '100%',

    gap: calcRem(4),

    [breakpoint('lg')]: {
        alignItems: 'center',
        flexDirection: 'row',
    },
})

export const BarPlaceholder = styled.div(
    {
        flexGrow: 1,

        height: calcRem(32),
    },
    placeholderAnimation,
)

export const TextPlaceholder = styled.div(
    {
        width: calcRem(125),
        height: calcRem(20),

        borderRadius: calcRem(4),
    },
    placeholderAnimation,
)
