import React, { PropsWithChildren, ReactNode } from 'react'
import {
    StyledLatestLiveBlogMilestonesContainer,
    StyledLiveBlogBulletMilestoneMark,
    StyledLiveBlogBulletPoint,
    StyledLiveBlogBulletPointContainer,
    StyledLiveBlogMilestoneRow,
    StyledLiveBlogRowContentContainer,
    StyledLiveBlogRowTimestamp,
    StyledLiveBlogRowTitle,
} from './LatestLiveBlogMilestones.styled'
import { IconBulletPoint } from '../../../../../../icons'
import { LatestLiveBlogContent } from '../../../../../../cards/LiveBlogs/content-selector-helper'
import { colorsPN } from '@news-mono/design-tokens'
import { getTimeAgoPNLong } from '@news-mono/web-common'

export type GetLinkWithChildrenComponent = (
    props: PropsWithChildren<{ to: string; key: string }>,
) => ReactNode

export interface PNLatestLiveBlogMilestonesProps {
    posts: LatestLiveBlogContent[]
    isVrTest?: boolean
    getLinkComponentWithChildren?: GetLinkWithChildrenComponent
    hasBackground?: boolean
}

export const PNLatestLiveBlogMilestones = ({
    posts,
    isVrTest,
    getLinkComponentWithChildren,
}: PNLatestLiveBlogMilestonesProps) => {
    const size = posts.length

    return (
        <StyledLatestLiveBlogMilestonesContainer>
            {posts.map((content, index) => {
                const RowComponent = (
                    <LiveBlogMilestoneRow
                        content={content}
                        isVrTest={isVrTest}
                        isLastRow={size - 1 === index}
                    />
                )
                return getLinkComponentWithChildren
                    ? getLinkComponentWithChildren({
                          to: content.link,
                          key: content.postId,
                          children: RowComponent,
                      })
                    : RowComponent
            })}
        </StyledLatestLiveBlogMilestonesContainer>
    )
}

const LiveBlogMilestoneRow = ({
    content,
    isVrTest,
    isLastRow,
}: {
    content: LatestLiveBlogContent
    isVrTest?: boolean
    isLastRow: boolean
}) => {
    const { title, publishedDate, isPinned } = content
    const truncTitle = isPinned ? `Pinned: ${title}` : title

    const publicationDateMessage = getTimeAgoPNLong(publishedDate)

    return (
        <StyledLiveBlogMilestoneRow>
            {/* Styling for the bullet point at the start of the post */}
            <StyledLiveBlogBulletPoint>
                <StyledLiveBlogBulletPointContainer isVrTest={isVrTest}>
                    <IconBulletPoint fill={colorsPN.fill.brand.strong} />
                </StyledLiveBlogBulletPointContainer>
                {!isPinned && <StyledLiveBlogBulletMilestoneMark />}
            </StyledLiveBlogBulletPoint>
            {/* Styling the content for row */}
            <StyledLiveBlogRowContentContainer isLastRow={isLastRow}>
                <StyledLiveBlogRowTitle>{truncTitle}</StyledLiveBlogRowTitle>
                <StyledLiveBlogRowTimestamp>
                    {publicationDateMessage}
                </StyledLiveBlogRowTimestamp>
            </StyledLiveBlogRowContentContainer>
        </StyledLiveBlogMilestoneRow>
    )
}
