import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    AppProps,
    PageResolver,
    ProvideRouteResolvedDataToTheme,
    SevenNewsSection,
    metrics,
} from '@news-mono/component-library'
import {
    ElectionDefinition,
    Product,
    RenderTargetContext,
    StaticRoutes,
    errorLocation,
    getRedirectRouteInfo,
    matchedErrorRoutePages,
    registerPageRoutes,
} from '@news-mono/web-common'
import { isArticleLikePublication } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { useLocation } from 'react-router'
import './App.css'
import { layout } from './App.routing'
import { Site, siteAds } from './Site'
import { TaboolaNewsRoom } from './TaboolaNewsroom/TaboolaNewsRoom'
import { staticRoutes } from './routes'
import { createAflMatchCentreRoute } from './routes/afl-match-centre-routes/get-afl-match-centre-route'
import { createAflwMatchCentreRoute } from './routes/aflw-match-centre-routes/get-aflw-match-centre-route'
import { createCricketMatchCentreRoute } from './routes/cricket-match-centre-routes/get-cricket-match-centre-route'
import { createDiagPage } from './routes/diag-routes'
import {
    matchedSomethingWentWrongRoute,
    notFoundRoute,
    staticSomethingWentWrongRoute,
} from './routes/errors'
import { getProfilePageRouteInfo } from './routes/profile/get-profile-page-route-info'
import { createArticleRoute } from './routes/publication/kind/article'
import { createGalleryRoute } from './routes/publication/kind/gallery'

import { topHeadlines } from 'apps/sevennews/src/app/routes/shared-components/top-headlines'
import { getSubtopicPageData } from './routes/custom/helpers/get-subtopic-page-data'
import { getV2SubtopicPage } from './routes/custom/helpers/get-v2-subtopic-page'
import { createEpisodeRoute } from './routes/publication/kind/video-series-episode'
import {
    breakingNews,
    headerAd,
} from './routes/shared-components/ad-and-breaking-news'
import { breadcrumb } from './routes/shared-components/breadcrumb'
import {
    box,
    stickyWithOffset,
    zeus,
} from './routes/shared-components/compositions'
import { mobileBespokeNativeAd } from './routes/shared-components/mobile-ads'
import { sidebarAdMrecOne } from './routes/shared-components/sidebar-ads'
import { getSeriesSynopsis } from './routes/video-series/get-series-synopsis'
import { getV2VideoHomePageRoute } from './routes/video/get-v2-video-page-route'
import { getV2VideoRoute } from './routes/video/get-v2-video-route'
import { getV2VideoTopicPageRoute } from './routes/video/get-v2-video-topic-page-route'
import queryString from 'query-string'
import { getSearchPageRouteInfo } from './routes/search/get-search-page-route-info'
import xss from 'xss'

const extractPageNumber = (url: string) => {
    const match = url.match(/\bpage=(\d+)/)
    if (match) {
        return parseInt(xss(match[1]))
    }
    return null
}
export function initRouting() {
    registerPageRoutes({
        staticRoutes,
        matchRoutes: {
            '^/election-widgets/([\\w-]+)$': ({
                location,
                resolution,
                config,
                log,
                getAdTargeting,
            }) => {
                const match = location.pathname
                    .replace(/\/$/, '')
                    .match(/\/election-widgets\/([\w-]+)/)
                if (!match) return null
                const queryParams = queryString.parse(location.search)
                const electionId =
                    (queryParams.electionId as string) || 'wa-election-2025'
                const electionDefinition: ElectionDefinition = {
                    electionId,
                    electionData: undefined,
                    logo: { mobile: '' },
                    meta: {},
                }

                const widgetComponents: Record<string, any> = {
                    'head-to-head-seat-count': {
                        type: 'election-head-to-head-seat-count-widget',
                        props: {
                            electionDefinition,
                        },
                    },
                    'map-overview': {
                        type: 'election-map-overview-widget',
                        props: {
                            electionDefinition,
                        },
                    },
                    'the-seats': {
                        type: 'election-seat-widget',
                        props: {
                            electionDefinition,
                            electoratePageUrl: `/politics/${electionId}/electorates`,
                        },
                    },
                    'the-race': {
                        type: 'election-the-race-widget',
                        props: {
                            electionDefinition,
                        },
                    },
                    'party-totals': {
                        type: 'election-party-totals',
                        props: {
                            electionDefinition,
                            description: 'Party totals for the 2025 election',
                        },
                    },
                    'election-needle': {
                        type: 'federal-election-needle',
                        props: {
                            electionDefinition,
                        },
                    },
                }

                const widgetName = match[1] as keyof typeof widgetComponents
                const widgetConfig = widgetComponents[widgetName]
                if (!widgetConfig) return null
                return {
                    kind: 'page',
                    heading: '',
                    pageType: 'simple-publication',
                    section: 'default',
                    adTargeting: {
                        pageId: 'election-widget',
                        adUnitPath: '/disable-ads',
                        ssAdUnits: [],
                        topics: [],
                    },
                    pageMeta: {
                        meta: [
                            {
                                'http-equiv': 'content-security-policy',
                                content: `frame-ancestors 'self'`,
                            },
                        ],
                    },
                    additionalPageProperties: {
                        isWidget: true,
                    },
                    compositions: [
                        layout.composition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [layout.component(widgetConfig)],
                            },
                        }),
                    ],
                }
            },
            '/search$': (services) => {
                const is7NewsSearchBarEnabled = isFeatureEnabled(
                    toFeatureState(services.store.getState().toggles),
                    '7-news-search-bar',
                )
                if (!is7NewsSearchBarEnabled) {
                    return null
                }

                const seoPage = extractPageNumber(services.location.search)

                return getSearchPageRouteInfo(
                    queryString.parse(services.location.search),
                    seoPage,
                    services.hostname,
                    services.location.pathname,
                )(services)
            },
            '/diag$': ({ config, resolution }) => {
                const section =
                    resolution.type === 'match' && resolution.section
                        ? resolution.section
                        : 'default'
                return createDiagPage(config, section as SevenNewsSection)
            },
            // The following are required for sectioned something went wrong pages
            [`${errorLocation}$`]: ({ getAdTargeting, resolution }) =>
                matchedSomethingWentWrongRoute(getAdTargeting, resolution),
            ...(matchedErrorRoutePages as StaticRoutes<SevenNewsSection>),
        },
        serverRoutes: {
            elections: () => null,
            profile: ({
                config,
                getAdTargeting,
                resolution,
                location,
                store,
            }) => {
                return getProfilePageRouteInfo({
                    profile: resolution.resolution.profile,
                    section: resolution.resolution.section as SevenNewsSection,
                    config,
                    getAdTargeting,
                    location,
                    store,
                })
            },
            redirect: ({ resolution }) => {
                return getRedirectRouteInfo(
                    resolution.resolution.redirectTo.targetUrl,
                )
            },
            topic: ({
                resolution,
                getAdTargeting,
                store,
                location,
                hostname,
            }) => {
                const topic = resolution.resolution.topic
                const subTopicData = getSubtopicPageData(topic.id)
                if (!subTopicData) {
                    return null
                }
                const seoPage = extractPageNumber(location.search)
                return getV2SubtopicPage({
                    topic,
                    subTopicData: subTopicData.data,
                    getAdTargeting,
                    features: store.getState().toggles,
                    seoPage,
                    currentUrl: hostname,
                    pathname: location.pathname,
                })
            },
            publication: ({
                resolution,
                config,
                getAdTargeting,
                renderTarget,
                store,
            }) => {
                if (
                    resolution.resolution.publication.kind === 'redirect' &&
                    resolution.resolution.publication.redirectUrl
                ) {
                    return getRedirectRouteInfo(
                        resolution.resolution.publication.redirectUrl,
                    )
                }
                if (
                    isArticleLikePublication(resolution.resolution.publication)
                ) {
                    return createArticleRoute({
                        config,
                        section: resolution.resolution.section as any,
                        article: resolution.resolution.publication,
                        getAdTargeting,
                        renderTarget,
                        store,
                    })
                }
                if (resolution.resolution.publication.kind === 'gallery') {
                    return createGalleryRoute({
                        gallery: resolution.resolution.publication,
                        numberGalleryItems:
                            resolution.resolution.publication.imageCount,
                        section: resolution.resolution
                            .section as SevenNewsSection,
                        getAdTargeting,
                        pageMeta: resolution.resolution.meta,
                        toggles: store.getState().toggles,
                    })
                }
                return null
            },
            preview: ({
                resolution,
                config,
                getAdTargeting,
                renderTarget,
                store,
            }) => {
                if (
                    isArticleLikePublication(resolution.resolution.publication)
                ) {
                    return createArticleRoute({
                        config,
                        section: resolution.resolution.section as any,
                        article: resolution.resolution.publication,
                        getAdTargeting,
                        renderTarget,
                        store,
                    })
                }

                return null
            },
            'video-series': ({ getAdTargeting, resolution, store }) => {
                if (
                    !isFeatureEnabled(
                        toFeatureState(store.getState().toggles),
                        'video-series',
                    )
                ) {
                    return null
                }

                return {
                    heading: resolution.resolution.series,
                    hideHeading: true,
                    pageMeta: {
                        ...resolution.resolution.meta,
                        title: resolution.resolution.meta?.title,
                    },
                    kind: 'page',
                    pageType: 'video-series',
                    adTargeting: getAdTargeting(
                        'home',
                        resolution.resolution.section,
                        resolution.resolution.series,
                    ),
                    socialMeta: {
                        title: 'Spotlight: ' + resolution.resolution.series,
                        description: resolution.resolution.meta?.description,
                    },
                    section: resolution.resolution.section,
                    compositions: [
                        headerAd(),
                        breakingNews,
                        breadcrumb({
                            items: [
                                {
                                    text: 'Spotlight',
                                    href: `/${resolution.resolution.section}`,
                                },
                                { text: resolution.resolution.series },
                            ],
                            enableHeaderTag: false,
                        }),
                        zeus({
                            main: [
                                layout.component({
                                    type: 'static-page-title',
                                    props: {
                                        title: resolution.resolution.series,
                                    },
                                }),
                                layout.component({
                                    type: 'video-series-trailer',
                                    props: {},
                                }),
                                getSeriesSynopsis(resolution.resolution.series),
                                layout.component({
                                    type: 'video-series-episode-list',
                                    props: {},
                                }),
                            ],
                            sidebar: [
                                stickyWithOffset(
                                    [
                                        sidebarAdMrecOne,
                                        mobileBespokeNativeAd,

                                        topHeadlines(),
                                    ],
                                    ['7newsHeaderAd'],
                                    60,
                                ),
                            ],
                        }),
                        box({
                            propOverrides: {
                                horizontalGutters: 'md',
                                verticalGutters: ['unset', 'unset'],
                            },
                            main: [
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        noticePosition: 'above-center',
                                        hiddenUntilLoaded: false,
                                        padding: [
                                            metrics.sevennews.margins.md,
                                            0,
                                            0,
                                            0,
                                        ],
                                        slot: {
                                            id: 'leaderboard-billboard-two',
                                            size: 'leaderboardBillboard',
                                        },
                                        adType: 'inline',
                                    },
                                }),
                            ],
                        }),
                    ],
                }
            },
            'video-series-episode': ({ resolution, getAdTargeting, store }) => {
                if (
                    !isFeatureEnabled(
                        toFeatureState(store.getState().toggles),
                        'video-series',
                    )
                ) {
                    return null
                }

                const resolutionData = resolution.resolution
                // check if the season in the url matches the metadata of the resolved video
                const isValidSeason =
                    resolutionData.season ===
                    resolutionData.episodeMeta.seriesInfo?.season

                if (resolutionData && isValidSeason) {
                    return createEpisodeRoute({
                        episodeData: resolutionData,
                        section: resolutionData.section as SevenNewsSection,
                        getAdTargeting,
                    })
                }
                return null
            },
            video: ({ resolution, config, getAdTargeting, store }) => {
                if (
                    !isFeatureEnabled(
                        toFeatureState(store.getState().toggles),
                        '7-news-individual-video-watch-page',
                    )
                ) {
                    return null
                }

                return getV2VideoRoute({
                    config,
                    getAdTargeting: getAdTargeting,
                    video: resolution.resolution.videoMeta,
                    topic: resolution.resolution.topic,
                    store,
                })
            },
            'video-topic': ({ resolution, config, getAdTargeting, store }) => {
                if (
                    !isFeatureEnabled(
                        toFeatureState(store.getState().toggles),
                        '7-news-video-hub-topic-page',
                    )
                ) {
                    return null
                }
                if (resolution.resolution.topic.id == 'video-landing') {
                    return getV2VideoHomePageRoute({
                        config,
                        getAdTargeting: getAdTargeting,
                        topic: resolution.resolution.topic,
                        store,
                    })
                }

                return getV2VideoTopicPageRoute({
                    config,
                    getAdTargeting: getAdTargeting,
                    topic: resolution.resolution.topic,
                    store,
                })
            },
            'match-centre': ({
                config,
                getAdTargeting,
                resolution,
                location,
                store,
            }) => {
                return createCricketMatchCentreRoute({
                    config,
                    getAdTargeting,
                    location,
                    meta: resolution.resolution.meta,
                    competition: resolution.resolution.competition,
                    match: resolution.resolution.match,
                    season: resolution.resolution.season,
                    apiData: resolution.resolution.apiData,
                    store,
                })
            },
            'afl-match-centre': ({
                config,
                getAdTargeting,
                resolution,
                location,
                store,
            }) => {
                if (resolution.resolution.isAflw) {
                    return createAflwMatchCentreRoute({
                        config,
                        getAdTargeting,
                        location,
                        meta: resolution.resolution.meta,
                        match: resolution.resolution.match,
                        competition: resolution.resolution.competition,
                        season: resolution.resolution.season,
                        apiData: resolution.resolution.apiData,
                        store,
                    })
                }
                return createAflMatchCentreRoute({
                    config,
                    getAdTargeting,
                    location,
                    meta: resolution.resolution.meta,
                    match: resolution.resolution.match,
                    competition: resolution.resolution.competition,
                    season: resolution.resolution.season,
                    apiData: resolution.resolution.apiData,
                    store,
                })
            },
            editions: () => {
                return null
            },
        },

        errorRouteInfo: staticSomethingWentWrongRoute,
        notFoundRouteInfo: notFoundRoute,
    })
}
initRouting()

export const App: React.FC<AppProps> = ({
    onEvent,
    hostname,
    protocol,
    gptApi,
    services,
}) => {
    const location = useLocation()
    const { renderTarget } = React.useContext(RenderTargetContext)

    return (
        <>
            <TaboolaNewsRoom path={location.pathname} store={services.store} />
            <PageResolver
                layout={layout}
                hostname={hostname}
                protocol={protocol}
                location={location}
                onEvent={onEvent}
                siteAds={siteAds}
                services={services}
                renderTarget={renderTarget}
                gptApi={gptApi}
                renderPage={(
                    pageContents,
                    ads,
                    pageType,
                    section,
                    additionalPageProperties,
                ) => (
                    <ProvideRouteResolvedDataToTheme
                        product={Product.SevenNews}
                        section={section}
                    >
                        <Site
                            pageType={pageType}
                            section={section as SevenNewsSection}
                            additionalPageProperties={additionalPageProperties}
                            publicUrl={services.config.publicUrl}
                            onEvent={onEvent}
                            pageContents={pageContents}
                            location={location}
                            ads={ads}
                            config={services.config}
                            hostname={hostname || ''}
                        />
                    </ProvideRouteResolvedDataToTheme>
                )}
            />
        </>
    )
}
