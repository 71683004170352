import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { TheSeatsWidget } from './TheSeatsWidget'

type SeatWidgetRouteProps = {
    electionDefinition: ElectionDefinition
    electoratePageUrl: string
    seatDisplayCount?: number
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
}
export const SeatWidgetRegistration = createRegisterableComponent(
    'election-seat-widget',
    (props: SeatWidgetRouteProps, services) => {
        return (
            <TheSeatsWidget
                electionDefinition={props.electionDefinition}
                onEvent={services.onEvent}
                electoratePageUrl={props.electoratePageUrl}
                seatDisplayCount={props.seatDisplayCount}
                headingLevel={props.headingLevel}
                titleText={props.titleText}
                imageBaseUrl={services.config.imageBaseUrl}
            />
        )
    },
)
