import {
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    TheWestTheme,
    TheWestThemeConfig,
    SevenNewsTheme,
    SevenNewsThemeConfig,
} from '../../../__styling'

export type ElectionTppEstCardTheme<T extends ThemeConfig> = {
    title: {
        textType: T['textType']
    }
    partyInfo: {
        textType: T['textType']
    }
}

export const sevenNewsElectionTppEstCardTheme = (scheme: SevenNewsTheme) => {
    const theme: ElectionTppEstCardTheme<SevenNewsThemeConfig> = {
        title: {
            textType: 'header',
        },
        partyInfo: {
            textType: 'header',
        },
    }
    return theme
}

export const theNightlyElectionTppEstCardTheme = (scheme: TheNightlyTheme) => {
    const theme: ElectionTppEstCardTheme<TheNightlyThemeConfig> = {
        title: {
            textType: 'item',
        },
        partyInfo: {
            textType: 'item-small',
        },
    }
    return theme
}
export const theWestElectionTppEstCardTheme = (scheme: TheWestTheme) => {
    const theme: ElectionTppEstCardTheme<TheWestThemeConfig> = {
        title: {
            textType: 'bold/18',
        },
        partyInfo: {
            textType: 'medium/18',
        },
    }
    return theme
}
