import React from 'react'
import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import { PartyTotalsComponent } from './PartyTotals'

type PartyTotalsRouteProps = {
    electionDefinition: ElectionDefinition
    /** This description appears below the "Party Totals" heading and in the sharing component */
    description: string
}
export const PartyTotalsRegistration = createRegisterableComponent(
    'election-party-totals',
    (props: PartyTotalsRouteProps, { onEvent }) => {
        return <PartyTotalsComponent onEvent={onEvent} {...props} />
    },
)
