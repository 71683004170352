import React from 'react'
import { MeterPieChart, MeterPieChartItem } from '../components/MeterPieChart'
import {
    ElectorateSwingPercentContainer,
    StyledElectorateSwingContainer,
} from './FullElectorateSwing.styled'

interface ElectorateSeatsSwingProps {
    swing: number | null
    color: string
}

export const FullElectorateSwing = ({
    swing,
    color,
}: ElectorateSeatsSwingProps) => {
    if (swing === null || swing === undefined) {
        return null
    }

    swing = Math.round(swing * 10) / 10
    const min = -50
    const max = 50

    let start, end
    if (swing > 0) {
        start = 0
        end = swing
    } else if (swing < 0) {
        start = swing
        end = 0
    }

    return (
        <StyledElectorateSwingContainer>
            <ElectorateSwingPercentContainer>{`${Math.abs(
                swing,
            )}%`}</ElectorateSwingPercentContainer>
            <MeterPieChart
                min={min}
                max={max}
                innerCutoutRatio={0.6}
                items={
                    <>
                        <MeterPieChartItem
                            start={min}
                            end={max}
                            fill={'#E0E0E0'}
                        />
                        {start !== undefined && end !== undefined && (
                            <MeterPieChartItem
                                start={start}
                                end={end}
                                fill={color}
                            />
                        )}
                    </>
                }
            />
        </StyledElectorateSwingContainer>
    )
}
