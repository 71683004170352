import React from 'react'
import {
    FilterEvent,
    FilterQueryParam,
    getEventName,
} from '../../../result-filters'
import { DataLayerEventName } from '@news-mono/web-common'
import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { breakpoint, calcRem, TheNightlyThemeConfig } from '../../../__styling'
import { ElectionFilterValue } from '../../../contexts'

type FilterProps<T> = {
    /** Data Layer event function */
    onEvent: (event: FilterEvent) => void
    /** Name of the Data Layer event */
    dataLayerEventName:
        | DataLayerEventName.searchFilter
        | DataLayerEventName.savedArticlesFilter
    /** The text to show above the filter, describes the purpose of the filter */
    headingText: string
    /** The Originator field used in the onEvent function */
    dataLayerEventOriginator: string
    /** Query Param */
    electionFilter: ElectionFilterValue<T>
    hideOnDesktop?: boolean
}

export function ElectionFilter<T extends FilterQueryParam>({
    headingText,
    dataLayerEventOriginator,
    onEvent,
    dataLayerEventName,
    hideOnDesktop = false,
    electionFilter,
}: FilterProps<T>) {
    /** Updates query params and fires required Data Layer event */
    const onItemClicked = (listItem: T) => {
        electionFilter.setValue(listItem)

        const eventName = getEventName(electionFilter.id)

        if (eventName) {
            onEvent({
                type: dataLayerEventName,
                originator: dataLayerEventOriginator,
                payload: {
                    name: eventName,
                    value: electionFilter.value.paramName ?? listItem.paramName,
                },
            })
        }
    }

    return (
        <FilterContainer
            hideOnDesktop={hideOnDesktop}
            onSubmit={(e) => e.preventDefault()}
        >
            <StyledLabel>{headingText}</StyledLabel>
            <LinkList>
                {electionFilter.allValues.map((item, i) => (
                    <FilterItem
                        key={i}
                        name={electionFilter.id}
                        value={item.paramName}
                        type="submit"
                        className={
                            item.paramName === electionFilter.value.paramName
                                ? 'active'
                                : ''
                        }
                        onClick={() => onItemClicked(item)}
                    >
                        {item.displayName}
                    </FilterItem>
                ))}
            </LinkList>
        </FilterContainer>
    )
}

const { neutral } = tokens.thenightly.colors.palette

export const FilterContainer = styled.form<{ hideOnDesktop: boolean }>(
    ({ hideOnDesktop, theme }) => [
        {
            display: 'flex',
            flexDirection: 'column',
            gap: calcRem(8),
            padding: calcRem(8, 0),
        },
        theme.kind === 'thenightly' &&
            hideOnDesktop === true && {
                display: 'flex',
                [breakpoint('lg')]: {
                    display: 'none',
                },
            },
    ],
)

export const StyledLabel = styled.label(({ theme }) =>
    textMixin<TheNightlyThemeConfig>(theme, 'item-small'),
)

export const LinkList = styled.nav({
    display: 'flex',
    margin: 0,
    padding: 0,
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: calcRem(8),
    width: '100%',
})

export const FilterItem = styled.button(({ theme }) => {
    return [
        {
            // Reset default button styles
            border: 'none',
            background: 'none',

            cursor: 'pointer',
            padding: calcRem(6, 12),
            borderRadius: calcRem(40),
            outline: `${calcRem(1)} solid ${neutral[30]}`,
            outlineOffset: calcRem(-1),
            color: neutral[70],

            '&.active': {
                backgroundColor: neutral[100],
                outlineColor: neutral[100],
                color: neutral[0],

                ['&:is(:focus,:active)']: {
                    outlineColor: neutral[30],
                },
            },
            ['&:is(:focus,:active)']: {
                outlineColor: neutral[100],
            },
        },
        textMixin<TheNightlyThemeConfig>(theme, 'body-alt'),
    ]
})
