import styled from '@emotion/styled'
import React from 'react'
import {
    getComponentTheme,
    breakpointSwitch,
    calcRem,
} from '../../../__styling'
import { useFeature } from '@news-mono/web-common'

interface ElectionPredictionDisclaimerProps {
    paddingTop?: number;
}

export const ElectionPredictionDisclaimer: React.FC<ElectionPredictionDisclaimerProps> = ({ paddingTop }) => {
    const showPredictionDisclaimer = useFeature('election-data-disclaimer')
    
    if (!showPredictionDisclaimer) {
        return null
    }

    return (
        <>
            <StyledElectionPredictionDisclaimer paddingTop={paddingTop}>
                Our proprietary model analyses data to estimate likely seats.
            </StyledElectionPredictionDisclaimer>     
        </>
    )
}

// Container object, handles the background colours and the hover, & onClick!
export const StyledElectionPredictionDisclaimer = styled('p')<{paddingTop?: number}>(({ theme, paddingTop }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return [
        {
            textAlign: 'start',
            margin: 0,

            lineHeight: '125%',
            letterSpacing: calcRem(-0.32),
        },
        paddingTop && {
            paddingTop: calcRem(paddingTop),
        },
        electionTheme.text &&
        breakpointSwitch(electionTheme.text, (styles) => styles),

    ]
})
