import React from 'react'
import {
    BarPlaceholder,
    PlaceholderContainer,
    TextPlaceholder,
} from './TheRaceRowPlaceholder.styled'

export const TheRaceRowPlaceholder = () => (
    <PlaceholderContainer>
        <TextPlaceholder />
        <BarPlaceholder />
    </PlaceholderContainer>
)
