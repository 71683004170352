import styled from '@emotion/styled'
import { breakpoint, calcRem, TheNightlyThemeConfig } from '../../../__styling'
import { IconChevronRightTN } from '../../../icons/IconChevronRightTN/IconChevronRightTN'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { WebLink } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'

export const TheSeatsWidgetContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(24),
    alignSelf: 'stretch',

    padding: calcRem(16, 0),

    [breakpoint('lg')]: {
        padding: calcRem(0),
    },
})

export const TheSeatsWidgetHeaderLink = styled(WebLink)({
    // Remove anchor styling
    textDecoration: 'none',
    color: 'inherit',

    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const TheSeatsWidgetSeatGrid = styled('div')({
    display: 'grid',
    width: '100%',

    // Mobile & Tablet Styling
    gridTemplateColumns: '1fr',
    gap: calcRem(16),

    //Desktop Styling
    [breakpoint('lg')]: {
        gridTemplateColumns: '1fr 1fr',
        gap: calcRem(24),
    },
})

export const TheSeatsWidgetChevron = styled(IconChevronRightTN)({})

export const TheSeatsNoResultsText = styled.p(({ theme }) => {
    return [
        {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        textMixin<TheNightlyThemeConfig>(theme, 'item-small'),
    ]
})

export const TheSeatsDivider = styled.hr<{
    hideOnDesktop?: boolean
    hasMargin?: boolean
}>(({ hideOnDesktop = false, hasMargin = false }) => {
    return {
        display: 'flex',
        width: '100%',
        border: 'none',
        borderBottom: `1px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
        margin: hasMargin ? calcRem(8, 0, 24, 0) : 0,

        //Desktop Styling
        [breakpoint('lg')]: {
            display: hideOnDesktop ? 'none' : 'flex',
        },
    }
})
