import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem } from '../../../__styling'

export const FullElectorateIndicatorContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 0',
    position: 'relative',

    height: calcRem(64),
    background: tokens.thenightly.colors.palette.neutral[10],
    width: '100%',
})

export const FullElectorateIndicator = styled('div')<{
    widthPercent: number | null
    color: string
}>(({ widthPercent, color }) => ({
    display: 'flex',
    height: '100%',
    width: `${widthPercent ? Math.min(widthPercent, 100) : 0}%`,
    background: `${color}`,
    position: 'absolute',
    borderRight: `${calcRem(1)} solid rgba(0,0,0,0.2)`,
}))

export const FullElectorateRowContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
})

export const FullElectorateRowDetailsContainer = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
})

export const CandidateDetailsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: calcRem(16),
})

export const FullElectorateInnerRowContainer = styled('div')({
    display: 'flex',
    gap: calcRem(4),
    alignItems: 'center',
    flex: '1 0 0',
    flexDirection: 'row',
    width: '100%',
})

export const FullElectorateHeadingText = styled('div')({
    color: tokens.thenightly.colors.palette.neutral[100],
    fontSize: calcRem(16),
    fontWeight: 600,
    lineHeight: '125%',
    letterSpacing: calcRem(-0.32),
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
})

export const CandidateText = styled('div')({
    color: tokens.thenightly.colors.palette.neutral[100],
    fontSize: calcRem(12),
    fontWeight: 400,
    lineHeight: calcRem(16),
})

export const PercentageText = styled('div')({
    color: tokens.thenightly.colors.palette.neutral[100],
    fontSize: calcRem(16),
    fontWeight: 400,
    lineHeight: calcRem(24),
    letterSpacing: calcRem(-0.32),
    textAlign: 'right',
})

export const VotesText = styled('div')({
    color: tokens.thenightly.colors.palette.neutral[100],
    fontSize: calcRem(12),
    fontWeight: 400,
    lineHeight: calcRem(16),
    textAlign: 'right',
})

export const InformationGrid = styled('div')<{ isThreeColumn?: boolean }>(
    ({ isThreeColumn }) => ({
        display: 'grid',
        gridTemplateColumns: isThreeColumn
            ? `auto ${calcRem(90)} ${calcRem(80)}`
            : `${calcRem(80)} ${calcRem(80)}`,
        columnGap: calcRem(16),
        alignSelf: 'end',
        alignItems: isThreeColumn ? 'end' : 'normal',
        paddingRight: calcRem(16),
        textAlign: 'right',
        width: isThreeColumn ? '100%' : 'auto',

        [breakpoint('sm')]: {
            gridTemplateColumns: isThreeColumn
                ? `auto ${calcRem(120)} ${calcRem(80)}`
                : `${calcRem(80)} ${calcRem(80)}`,
        },
    }),
)
