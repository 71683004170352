import {
    DataLayerEventName,
    SavedArticlesFilterEvent,
    SearchEventArgs,
    SearchFilterEvent,
} from '@news-mono/web-common'
import { getPublishedSince, TIME_FILTER_PARAM } from './TimeFilter'
import { RESULT_SORT_PARAM, relevanceSortOption } from './ResultSort'
import { FilterQueryParam } from './FilterQueryParam'

export const TOPIC_FILTER_PARAM = 'topics'
const STATE_FILTER_PARAM = 'state'
// This list loosely reflects The Nightly quickLinks found in appsthenightlysrcappnavigation.ts
// It currently excludes "The Front Dore" link
export const articleTopics: FilterQueryParam[] = [
    {
        displayName: 'All',
        paramName: 'all',
    },
    {
        displayName: 'Politics',
        paramName: 'politics',
    },
    {
        displayName: 'Australia',
        paramName: 'australia',
    },
    {
        displayName: 'Business',
        paramName: 'business',
    },
    {
        displayName: 'Opinion',
        paramName: 'opinion',
    },
    {
        displayName: 'Culture',
        paramName: 'culture',
    },
    {
        displayName: 'Society',
        paramName: 'society',
    },
    {
        displayName: 'Lifestyle',
        paramName: 'lifestyle',
    },
    {
        displayName: 'Sport',
        paramName: 'sport',
    },
    {
        displayName: 'World',
        paramName: 'world',
    },
]

export const defaultTopicOption = articleTopics[0].displayName

export type FilterEvent = SearchFilterEvent | SavedArticlesFilterEvent

export interface FilterEventProps {
    onEvent: (event: FilterEvent) => void
    dataLayerEventName:
        | DataLayerEventName.searchFilter
        | DataLayerEventName.savedArticlesFilter
}

const topicEventName = 'Topics'
const resultSortEventName = 'Result Sort'
const timeFilterEventName = 'Time Filter'
const stateFilterEventName = 'State Filter'

export const filterParams = [
    TIME_FILTER_PARAM,
    RESULT_SORT_PARAM,
    TOPIC_FILTER_PARAM,
    STATE_FILTER_PARAM,
]

export const getEventName = (param: string) => {
    switch (param) {
        case TIME_FILTER_PARAM:
            return timeFilterEventName
        case RESULT_SORT_PARAM:
            return resultSortEventName
        case TOPIC_FILTER_PARAM:
            return topicEventName
        case STATE_FILTER_PARAM:
            return stateFilterEventName
    }
}

export const getSearchEventArgs = (
    searchTerm: string,
    searchParams: URLSearchParams,
): SearchEventArgs => {
    const publishedSince = getPublishedSince(
        searchParams.get(TIME_FILTER_PARAM) ?? undefined,
    )
    const topics = searchParams.get(TOPIC_FILTER_PARAM) ?? defaultTopicOption
    const sortBy =
        searchParams.get(RESULT_SORT_PARAM) ?? relevanceSortOption.paramName

    return {
        searchTerm,
        topics,
        sortBy,
        publishedSince,
    }
}
