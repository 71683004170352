import React from 'react'
import {
    CardEvents,
    CollectionEvent,
    PublicationCardItemWithoutVideo,
} from '@news-mono/web-common'
import {
    PNLatestLiveBlogMilestones,
    GetLinkWithChildrenComponent,
} from './components/LatestLiveBlogMilestones'
import { getLatestLiveBlog } from '../../../../../cards/LiveBlogs/content-selector-helper'

export interface PNLiveBlogSummaryProps {
    result: PublicationCardItemWithoutVideo
    isVrTest?: boolean
    getLinkComponentWithChildren?: GetLinkWithChildrenComponent
    onEvent: (event: CardEvents | CollectionEvent) => void
}

export const PNLiveBlogSummary = ({
    isVrTest,
    result,
    getLinkComponentWithChildren,
}: PNLiveBlogSummaryProps): JSX.Element | null => {
    const eventLiveBlogTextLength = 250

    // Fetch the posts for the live blog
    const latestLiveBlogPosts = getLatestLiveBlog(
        result.entries,
        eventLiveBlogTextLength,
        5,
    )

    // Dont return a component if no posts available
    if (!latestLiveBlogPosts || latestLiveBlogPosts.length === 0) {
        return null
    }

    return (
        <PNLatestLiveBlogMilestones
            isVrTest={isVrTest}
            posts={latestLiveBlogPosts}
            getLinkComponentWithChildren={getLinkComponentWithChildren}
        />
    )
}
