import styled from '@emotion/styled'

export const MeterPieChartContainer = styled.div({
    display: 'flex',
    position: 'relative',
    width: 80,

    aspectRatio: '2 / 1',
})

export const MeterPieChartMeterWrapper = styled.div<{
    /** In radians */
    rotation: number
}>(({ rotation }) => ({
    position: 'absolute',
    bottom: 0,
    left: '50%',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    translate: `-50% 50%`,
    rotate: `${rotation}rad`,

    overflow: 'visible',

    transition: 'rotate 2000ms',
}))
