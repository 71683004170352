import React, {
    createContext,
    PropsWithChildren,
    SVGProps,
    useContext,
    useEffect,
    useRef,
} from 'react'
import {
    MeterPieChartContainer,
    MeterPieChartMeterWrapper,
} from './MeterPieChart.styled'
import BezierEasing from 'bezier-easing'

const MeterPieChartContext = createContext<{
    min: number
    max: number
    /** How much of the inside to cut out, 0 = nothing, 0.5 = half the radius, 1 = all of it. */
    innerCutoutRatio: number
}>({ min: 0, max: 0, innerCutoutRatio: 0 })

type MeterPieChartProps = {
    min: number
    max: number
    innerCutoutRatio?: number
    items: JSX.Element
}

export const MeterPieChart = ({
    min,
    max,
    innerCutoutRatio = 0,
    items,
    children,
}: PropsWithChildren<MeterPieChartProps>) => {
    return (
        <MeterPieChartContext.Provider value={{ min, max, innerCutoutRatio }}>
            <MeterPieChartContainer>
                <svg viewBox="0 0 2 1">{items}</svg>
                {children}
            </MeterPieChartContainer>
        </MeterPieChartContext.Provider>
    )
}

const { abs, sin, cos, PI } = Math

type ArcPointsArgs = {
    max: number
    min: number
    start: number
    end: number
    innerCutoutRatio: number
}

const createArcPath = ({
    min,
    max,
    start,
    end,
    innerCutoutRatio,
}: ArcPointsArgs) => {
    const range = abs(max - min)

    const startRadians = PI * (1 - (start - min) / range)
    const endRadians = PI * ((max - end) / range)

    const startX = 1 + cos(startRadians)
    const startY = 1 - sin(startRadians)
    const endX = 1 + cos(endRadians)
    const endY = 1 - sin(endRadians)

    const innerCutoutStartX = 1 + innerCutoutRatio * cos(endRadians)
    const innerCutoutStartY = 1 - innerCutoutRatio * sin(endRadians)

    const innerCutoutEndX = 1 + innerCutoutRatio * cos(startRadians)
    const innerCutoutEndY = 1 - innerCutoutRatio * sin(startRadians)

    return `M${startX} ${startY}A1 1 0 0 1 ${endX} ${endY}L${innerCutoutStartX} ${innerCutoutStartY}A${innerCutoutRatio} ${innerCutoutRatio} 0 0 0 ${innerCutoutEndX} ${innerCutoutEndY} Z`
}

const interpolateArcArgs = (
    a: ArcPointsArgs,
    b: ArcPointsArgs,
    t: number,
): ArcPointsArgs => {
    const progress = BezierEasing(0.25, 0.1, 0.25, 1)(t)

    return {
        min: (1 - progress) * a.min + progress * b.min,
        max: (1 - progress) * a.max + progress * b.max,
        start: (1 - progress) * a.start + progress * b.start,
        end: (1 - progress) * a.end + progress * b.end,
        innerCutoutRatio:
            (1 - progress) * a.innerCutoutRatio + progress * b.innerCutoutRatio,
    }
}

type MeterPieChartItemProps = {
    start: number
    end: number
} & SVGProps<SVGPathElement>

const DURATION = 2000

export const MeterPieChartItem = ({
    className,
    start,
    end,
    ...props
}: MeterPieChartItemProps) => {
    const context = useContext(MeterPieChartContext)

    const { min, max, innerCutoutRatio } = context

    const clampedStart = Math.max(min, Math.min(max, start))
    const clampedEnd = Math.max(min, Math.min(max, end))

    const arcArgs: ArcPointsArgs = {
        min,
        max,
        start: clampedStart,
        end: clampedEnd,
        innerCutoutRatio,
    }

    const frameKeyRef = useRef(-1)
    const pathRef = useRef<SVGPathElement>(null)
    const initialArcArgsRef = useRef(arcArgs)

    const animationState = useRef<
        | { isAnimating: false }
        | {
              isAnimating: true
              startTime?: number
              currentArgs: ArcPointsArgs
          }
    >({
        isAnimating: false,
    })

    useEffect(() => {
        if (animationState.current.isAnimating) {
            cancelAnimationFrame(frameKeyRef.current)
            animationState.current.startTime = undefined
            initialArcArgsRef.current = animationState.current.currentArgs
        } else {
            animationState.current = {
                isAnimating: true,
                currentArgs: initialArcArgsRef.current,
            }
        }

        const animationUpdate = (now: number) => {
            if (!animationState.current.isAnimating) return

            if (!animationState.current.startTime) {
                animationState.current.startTime = now
            }

            const progress =
                Math.min(now - animationState.current.startTime, DURATION) /
                DURATION

            const currentArgs = interpolateArcArgs(
                initialArcArgsRef.current,
                arcArgs,
                progress,
            )
            animationState.current.currentArgs = currentArgs

            pathRef.current?.style.setProperty(
                'display',
                currentArgs.start > currentArgs.end ? 'none' : 'initial',
            )
            pathRef.current?.setAttribute('d', createArcPath(currentArgs))

            if (progress < 1) {
                const frameKey = requestAnimationFrame(animationUpdate)
                frameKeyRef.current = frameKey
                return
            }

            if (progress === 1) {
                animationState.current = {
                    isAnimating: false,
                }
                initialArcArgsRef.current = arcArgs
            }
        }

        const frameKey = requestAnimationFrame(animationUpdate)
        frameKeyRef.current = frameKey

        return () => {
            cancelAnimationFrame(frameKeyRef.current)
        }
    }, [start, end, min, max])

    const dRef = useRef(createArcPath(arcArgs))

    return <path {...props} ref={pathRef} d={dRef.current} />
}

type MeterPieChartMeterProps = {
    value: number
}

export const MeterPieChartMeter = ({
    value,
    children,
}: PropsWithChildren<MeterPieChartMeterProps>) => {
    const { min, max } = useContext(MeterPieChartContext)
    const clampedValue = Math.max(min, Math.min(max, value))

    const rotation = PI * (clampedValue / abs(max - min))

    return (
        <MeterPieChartMeterWrapper rotation={rotation}>
            {children}
        </MeterPieChartMeterWrapper>
    )
}
