import { tokens } from '@news-mono/design-tokens'
import { BreakpointState, calcRem, Theme, ThemeConfig } from '../../__styling'

type ElectionTextType = {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    fontStyle?: string
    lineHeight?: string
    letterSpacing?: string
}
type ElectionButtonType = {
    backgroundColor: string
    hoverColor: string
}

const tnPalette = tokens.thenightly.colors.palette

export type ElectionWidgetTheme<T extends ThemeConfig> = {
    title: BreakpointState<T, ElectionTextType>
    text: BreakpointState<T, ElectionTextType>
    note: BreakpointState<T, ElectionTextType>
    input: {
        backgroundColor: string
        textColor: string
        outlineColor: string
        outlineHoverColor: string
        fontFamily: string
        fontSize: string
        fontWeight: number
        lineHeight?: string
        letterSpacing?: string
    }

    button: {
        title: ElectionButtonType
        border: string
    }
}

// Currently styled to TheNightly, but setup as a default for all mastheads,
// when styling for TheWest, etc. split this out into mastheads.
export const defaultElectionWidgetTheme = (
    theme: Theme,
): ElectionWidgetTheme<ThemeConfig> => {
    return {
        title: {
            default: {
                fontFamily: theme.fonts.heading,
                fontSize: calcRem(32),
                fontWeight: 600,
                color: theme.colors.text.heading,
                fontStyle: 'normal',
                lineHeight: calcRem(48),
                letterSpacing: calcRem(-1.2),
            },
        },
        text: {
            default: {
                fontFamily: 'Inter',
                fontSize: calcRem(16),
                fontWeight: 400,
                color:
                    tokens.thenightly.colors.text.tertiary ||
                    tnPalette.neutral[70],
            },
        },
        note: {
            default: {
                fontFamily: 'Inter',
                fontSize: calcRem(12),
                fontWeight: 400,
                color:
                    tokens.thenightly.colors.text.tertiary ||
                    tnPalette.neutral[70],
            },
        },
        button: {
            title: {
                backgroundColor: 'transparent',
                hoverColor: tnPalette.neutral[5],
            },
            border: tnPalette.neutral[100],
        },
        input: {
            backgroundColor: tnPalette.neutral[10],
            textColor: tnPalette.neutral[70],
            outlineColor: tnPalette.neutral[20],
            outlineHoverColor: tnPalette.neutral[70],
            fontFamily: theme.fonts.sansSerif,
            fontSize: calcRem(16),
            fontWeight: 400,
            lineHeight: calcRem(24),
            letterSpacing: '-2%',
        },
    }
}

export const theWestElectionWidgetTheme = (
    theme: Theme,
): ElectionWidgetTheme<ThemeConfig> => {
    return {
        title: {
            default: {
                fontFamily: theme.fonts.heading,
                fontSize: calcRem(39),
                fontWeight: 400,
                color: tokens.thenightly.colors.text.heading,
                fontStyle: 'normal',
                lineHeight: calcRem(48),
                letterSpacing: '0',
            },
        },
        text: {
            default: {
                fontFamily: theme.fonts.heading,
                fontSize: calcRem(16),
                fontWeight: 400,
                lineHeight: calcRem(24),
                color:
                    tokens.thenightly.colors.text.tertiary ||
                    tnPalette.neutral[70],
            },
        },
        note: {
            default: {
                fontFamily: theme.fonts.sansSerif,
                fontSize: calcRem(13),
                fontWeight: 400,
                lineHeight: calcRem(16),
                color:
                    tokens.thenightly.colors.text.tertiary ||
                    tnPalette.neutral[70],
            },
        },
        button: {
            title: {
                backgroundColor: 'transparent',
                hoverColor: tnPalette.neutral[5],
            },
            border: tnPalette.neutral[100],
        },
        input: {
            backgroundColor: tnPalette.neutral[10],
            textColor: tnPalette.neutral[70],
            outlineColor: tnPalette.neutral[20],
            outlineHoverColor: tnPalette.neutral[70],
            fontFamily: theme.fonts.sansSerif,
            fontSize: calcRem(16),
            fontWeight: 400,
            lineHeight: calcRem(24),
        },
    }
}
