import { tokens } from '@news-mono/design-tokens'
import React from 'react'
import { calcRem } from '../../../__styling'

export interface RaceLine44Props {
    color?: string
    width?: number
    height?: number
}

export const RaceLine44 = ({
    width = 1,
    height = 686,
    color,
}: RaceLine44Props) => {
    const lineColor = color || tokens.thenightly.colors.palette.neutral[70]

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 1 686`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ overflow: 'visible', paddingTop: calcRem(2) }}
        >
            <line
                x1="0.5"
                y1="-2.18557e-08"
                x2="0.50003"
                y2="686"
                stroke={lineColor}
                strokeDasharray={'4 4'}
            />
        </svg>
    )
}
