import React, { memo, useEffect, useRef, useState } from 'react'
import {
    getPartyColors,
    partyColors,
    SeatAllCandidates,
    SeatData,
    SeatTwoCandidatePreferred,
} from '../data/election-data'
import {
    SeatResultsContainer,
    SeatVotesContainer,
    SeatVoteCountContainer,
    SeatCandidateDetails,
    SeatBarGraphContainer,
    SeatBarGraphSlidingBar,
    SeatBarGraphDivider,
    SeatStatusContainer,
    SeatBarGraphSlidingBars,
} from '../SeatCard/SeatCard.styled'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'
import {
    ElectorateCandidatePartyText,
    ElectorateDetailsContainer,
    ElectorateDetailsExpander,
    FullElectorateCandidateContainer,
    FullElectorateCandidateImage,
    FullElectorateCandidateImageContainer,
    FullElectorateCandidateNameText,
    FullElectorateCandidatesContainer,
    FullElectorateCandidateTppText,
    FullElectorateContainer,
    FullElectorateDetailsText,
    FullElectorateHeaderContainer,
    FullElectoratePartyText,
    FullElectorateSeatDetailsContainer,
    FullElectorateStatusText,
    FullElectorateSwingContainer,
    FullElectorateSwingLabel,
    FullElectorateVoteCountText,
    IncumbentLabel,
    ShowMoreButtonTW,
    StyledChevron,
} from './FullElectorateCard.styled'
import { FullElectorateCardRow } from '../FullElectorateRow/FullElectorateRow'
import {
    FullElectorateHeadingText,
    InformationGrid,
} from '../FullElectorateRow/FullElectorateRow.styled'
import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import { FullElectorateSwing } from './FullElectorateSwing'
import { useTheme } from '@emotion/react'
import { getInitials, handleImageEvent } from '../components/helpers'

interface SeatCardProps {
    seat?: SeatData
    isLoading?: boolean
    onEvent: (event: AllEvents) => void
    isExpanded?: boolean
}

export const FullElectorateCard: React.FC<SeatCardProps> = memo(
    ({ seat, isLoading = false, onEvent, isExpanded = false }) => {
        const theme = useTheme()
        const [isOpen, setIsOpen] = React.useState(isExpanded)

        if (isLoading || !seat) {
            return <FullElectorateContainer isLoading={isLoading} />
        }

        const {
            seatName,
            state,
            candidates,
            winningParty,
            status,
            winningPartyDarkColor,
            allCandidates,
        } = seat

        return (
            <FullElectorateContainer isLoading={isLoading}>
                <FullElectorateHeaderContainer>
                    <FullElectorateSeatDetailsContainer>
                        <span>
                            <FullElectorateDetailsText variant="name">
                                {seatName}
                            </FullElectorateDetailsText>
                            <FullElectorateDetailsText variant="state">
                                {state}
                            </FullElectorateDetailsText>
                        </span>
                        <FullElectorateCandidateTppText variant="mobile">
                            {getCandidateTextState(candidates, allCandidates)}
                        </FullElectorateCandidateTppText>
                    </FullElectorateSeatDetailsContainer>
                    {status !== 'Not Called' &&
                        winningParty &&
                        winningPartyDarkColor && (
                            <SeatStatusContainer color={winningPartyDarkColor}>
                                <FullElectorateStatusText>
                                    {winningParty} {status}
                                </FullElectorateStatusText>
                            </SeatStatusContainer>
                        )}
                </FullElectorateHeaderContainer>
                <SeatResults
                    candidates={candidates}
                    allCandidates={allCandidates}
                />
                <ElectorateDetailsExpander isOpen={isOpen}>
                    <ElectorateDetailsContainer>
                        <InformationGrid isThreeColumn>
                            <FullElectorateHeadingText
                                style={{ textAlign: 'left' }}
                            >
                                First Preferences
                            </FullElectorateHeadingText>
                            <FullElectorateHeadingText>
                                Primary Vote
                            </FullElectorateHeadingText>
                            <FullElectorateHeadingText>
                                Swing
                            </FullElectorateHeadingText>
                        </InformationGrid>
                        {seat.allCandidates.map((candidate) => {
                            return (
                                <FullElectorateCardRow
                                    key={candidate.candidateName}
                                    candidate={candidate}
                                />
                            )
                        })}
                    </ElectorateDetailsContainer>
                </ElectorateDetailsExpander>
                {theme.kind === 'thenightly' ? (
                    <NightlyButton
                        variant={'default'}
                        text={isOpen ? 'Show Less' : 'Show More'}
                        action={{
                            type: 'button',
                            onClick: () => {
                                onEvent({
                                    type: DataLayerEventName.showMoreToggle,
                                    originator: 'ElectionFullElectorateWidget',
                                    payload: {
                                        toggle: `${isOpen ? 'less' : 'more'}`,
                                        details: `${seatName}`,
                                    },
                                })
                                setIsOpen(!isOpen)
                            },
                        }}
                        color={'primary'}
                        fill={'text'}
                        icon={{
                            IconElement: <StyledChevron isOpen={isOpen} />,
                            iconPosition: 'right',
                        }}
                    />
                ) : (
                    <ShowMoreButtonTW
                        onClick={() => {
                            onEvent({
                                type: DataLayerEventName.showMoreToggle,
                                originator: 'ElectionFullElectorateWidget',
                                payload: {
                                    toggle: `${isOpen ? 'less' : 'more'}`,
                                    details: `${seatName}`,
                                },
                            })
                            setIsOpen(!isOpen)
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                            }}
                        >
                            {isOpen ? 'Show less' : 'Show more'}{' '}
                            <StyledChevron isOpen={isOpen} />
                        </div>
                    </ShowMoreButtonTW>
                )}
            </FullElectorateContainer>
        )
    },
)

const SeatResults: React.FC<{
    candidates: SeatTwoCandidatePreferred[]
    allCandidates: SeatAllCandidates[]
}> = ({ candidates, allCandidates }) => {
    const [candidateOne, candidateTwo] = getSeatCandidates(
        candidates,
        allCandidates,
    )

    const isTppState = candidates.length !== 0

    return (
        <SeatResultsContainer>
            <SeatVotesContainer>
                <VoteDisplay
                    votes={candidateOne.primaryVotes}
                    percentage={candidateOne.currentVotesPercentage}
                    color={candidateOne.partyColor.dark}
                    partyName={candidateOne.shortPartyName}
                    reverse={true}
                    showPercentage={isTppState}
                />
                <FullElectorateCandidateTppText variant="desktop">
                    {getCandidateTextState(candidates, allCandidates)}
                </FullElectorateCandidateTppText>
                <VoteDisplay
                    votes={candidateTwo.primaryVotes}
                    percentage={candidateTwo.currentVotesPercentage}
                    color={candidateTwo.partyColor.dark}
                    partyName={candidateTwo.shortPartyName}
                    reverse={false}
                    showPercentage={isTppState}
                />
            </SeatVotesContainer>
            <SeatBarGraph
                candidateOneColor={candidateOne.partyColor.primary}
                candidateTwoColor={candidateTwo.partyColor.primary}
                candidateOnePct={candidateOne.currentVotesPercentage}
            ></SeatBarGraph>
            <FullElectorateCandidatesContainer>
                <CandidateDisplay
                    candidateName={candidateOne.candidateName}
                    color={candidateOne.partyColor.primary}
                    opposingColor={candidateTwo.partyColor.primary}
                    partyName={candidateOne.shortPartyName}
                    opposingPartyName={candidateTwo.partyName}
                    incumbent={candidateOne.incumbent}
                    imageUrl={candidateOne.imageUrl}
                    reverse={false}
                    swing={candidateOne.swing}
                    isIncumbentParty={candidateOne.isIncumbentParty}
                />
                <CandidateDisplay
                    candidateName={candidateTwo.candidateName}
                    color={candidateTwo.partyColor.primary}
                    opposingColor={candidateOne.partyColor.primary}
                    partyName={candidateTwo.shortPartyName}
                    opposingPartyName={candidateTwo.partyName}
                    incumbent={candidateTwo.incumbent} // NB this should always be false, or something has gone very wrong with democracy
                    imageUrl={candidateTwo.imageUrl}
                    reverse={true}
                    swing={candidateTwo.swing}
                    isIncumbentParty={candidateTwo.isIncumbentParty}
                />
            </FullElectorateCandidatesContainer>
        </SeatResultsContainer>
    )
}

const VoteDisplay: React.FC<{
    votes: number
    percentage: number | null
    color: string
    partyName: string | null
    reverse?: boolean
    showPercentage?: boolean
}> = ({
    votes,
    percentage,
    color,
    partyName,
    reverse = false,
    showPercentage = true,
}) => (
    <SeatVoteCountContainer color={color}>
        {reverse ? (
            <>
                {showPercentage && (
                    <FullElectorateVoteCountText bold>
                        {percentage}%
                    </FullElectorateVoteCountText>
                )}
                <FullElectorateVoteCountText>
                    {votes.toLocaleString()}
                </FullElectorateVoteCountText>
                <FullElectoratePartyText>{partyName}</FullElectoratePartyText>
            </>
        ) : (
            <>
                <FullElectoratePartyText>{partyName}</FullElectoratePartyText>
                <FullElectorateVoteCountText>
                    {votes.toLocaleString()}
                </FullElectorateVoteCountText>
                {showPercentage && (
                    <FullElectorateVoteCountText bold>
                        {percentage}%
                    </FullElectorateVoteCountText>
                )}
            </>
        )}
    </SeatVoteCountContainer>
)

const CandidateDisplay: React.FC<{
    candidateName: string
    color: string
    partyName: string | null
    opposingPartyName: string | null
    opposingColor: string
    incumbent: boolean
    imageUrl: string
    swing: number | null
    reverse?: boolean
    isIncumbentParty: boolean
}> = ({
    candidateName,
    color,
    opposingColor,
    partyName,
    opposingPartyName,
    incumbent,
    imageUrl,
    reverse = false,
    swing,
    isIncumbentParty,
}) => {
    const imgRef = useRef<HTMLImageElement>(null)
    const [imgSrc, setImgSrc] = useState(imageUrl)

    useEffect(() => {
        setImgSrc(`${imageUrl}&${new Date().getTime()}`)
    }, [imageUrl])

    return (
        <FullElectorateCandidateContainer>
            {reverse ? (
                <>
                    <SeatCandidateDetails reverse={reverse}>
                        <ElectorateCandidatePartyText color={color}>
                            {partyName}
                            {incumbent ? (
                                <span>&nbsp;(Incumbent)</span>
                            ) : isIncumbentParty ? (
                                <span>&nbsp;(Incumbent Party)</span>
                            ) : null}
                        </ElectorateCandidatePartyText>
                        <FullElectorateCandidateNameText>
                            {candidateName}
                        </FullElectorateCandidateNameText>
                        {incumbent && (
                            <IncumbentLabel>Incumbent</IncumbentLabel>
                        )}
                        {incumbent && (
                            <IncumbentLabel>Incumbent Party</IncumbentLabel>
                        )}
                    </SeatCandidateDetails>
                    <FullElectorateCandidateImageContainer
                        initials={getInitials(candidateName)}
                    >
                        <FullElectorateCandidateImage
                            ref={imgRef}
                            src={imgSrc}
                            alt={candidateName}
                            onError={(e) => handleImageEvent(e)}
                        />
                    </FullElectorateCandidateImageContainer>
                    {incumbent && (
                        <FullElectorateSwing swing={swing} color={color} />
                    )}
                </>
            ) : (
                <>
                    <FullElectorateCandidateImageContainer
                        initials={getInitials(candidateName)}
                    >
                        <FullElectorateCandidateImage
                            ref={imgRef}
                            src={imgSrc}
                            alt={candidateName}
                            onError={(e) => handleImageEvent(e)}
                        />
                    </FullElectorateCandidateImageContainer>
                    <SeatCandidateDetails reverse={reverse}>
                        <ElectorateCandidatePartyText color={color}>
                            {partyName}
                            {incumbent ? (
                                <span>&nbsp;(Incumbent)</span>
                            ) : isIncumbentParty ? (
                                <span>&nbsp;(Incumbent Party)</span>
                            ) : null}
                        </ElectorateCandidatePartyText>
                        <FullElectorateCandidateNameText>
                            {candidateName}
                        </FullElectorateCandidateNameText>
                        {incumbent && (
                            <IncumbentLabel>Incumbent</IncumbentLabel>
                        )}
                        {isIncumbentParty && (
                            <IncumbentLabel>Incumbent Party</IncumbentLabel>
                        )}
                    </SeatCandidateDetails>
                    {incumbent &&
                        swing !== null &&
                        !isNaN(swing) &&
                        // interknowlogy api returns ChangeTcpPct of 0 (not
                        // null) for candidates that were not in the previous
                        // election. This is probably a bug in the api, and
                        // it's misleading, so we hide the swing when it's
                        // exactly zero.
                        swing !== 0 && (
                            <FullElectorateSwingContainer>
                                <FullElectorateSwing
                                    swing={swing * -1}
                                    color={swing > 0 ? color : opposingColor}
                                />
                                <FullElectorateSwingLabel>{`Swing ${
                                    swing > 0 ? 'to' : 'from'
                                } ${partyName}`}</FullElectorateSwingLabel>
                            </FullElectorateSwingContainer>
                        )}
                </>
            )}
        </FullElectorateCandidateContainer>
    )
}

export const SeatBarGraph: React.FC<{
    candidateOneColor: string
    candidateTwoColor: string
    candidateOnePct: number | null
}> = ({ candidateOneColor, candidateTwoColor, candidateOnePct }) => {
    return (
        <SeatBarGraphContainer>
            <SeatBarGraphSlidingBars>
                {candidateOnePct !== null && (
                    <>
                        <SeatBarGraphSlidingBar
                            color={candidateOneColor}
                            width={candidateOnePct}
                        />
                        <SeatBarGraphSlidingBar
                            color={candidateTwoColor}
                            width={100 - candidateOnePct}
                        />
                    </>
                )}
            </SeatBarGraphSlidingBars>
            <SeatBarGraphDivider />
        </SeatBarGraphContainer>
    )
}

export const getSeatCandidates = (
    candidates: SeatTwoCandidatePreferred[],
    allCandidates: SeatAllCandidates[],
): SeatTwoCandidatePreferred[] => {
    let [candidateOne, candidateTwo] = candidates

    const isTppState = candidates.length !== 0
    /**
     * The candidates array can be empty if no Two Party Preferred data is available from the origin API.
     * In this case, we will use the first two candidates from the allCandidates array. (this is pre-sorted by primaryVotes)
     */
    if (!isTppState) {
        const incumbentCandidate = allCandidates.find(
            (candidate) => candidate.isIncumbent || candidate.isIncumbentParty,
        )
        const nonIncumbentCandidate = allCandidates.find(
            (candidate) =>
                !candidate.isIncumbent && !candidate.isIncumbentParty,
        )
        // If there is an incumbent candidate, put them in the first position (the left)
        if (incumbentCandidate) {
            // Add up the votes of the top two candidates, this is used to calculate the 'percentage' of votes
            const topPartyVotes =
                incumbentCandidate.primaryVotes +
                (nonIncumbentCandidate?.primaryVotes ?? 0) // If for some reason there is no non-incumbent candidate, default to 0

            const incumbentPercentage = topPartyVotes
                ? (incumbentCandidate.primaryVotes / topPartyVotes) * 100
                : 0

            candidateOne = {
                ...incumbentCandidate,
                // Calculate the percentage of the candidates votes out of 100%
                currentVotesPercentage: incumbentPercentage,
                incumbent: incumbentCandidate.isIncumbent,
            }
            if (nonIncumbentCandidate) {
                const nonIncumbentPercentage = topPartyVotes
                    ? (nonIncumbentCandidate.primaryVotes / topPartyVotes) * 100
                    : 0
                candidateTwo = {
                    ...nonIncumbentCandidate,
                    currentVotesPercentage: nonIncumbentPercentage,
                    incumbent: nonIncumbentCandidate.isIncumbent,
                }
            }
        } else {
            if (allCandidates.length >= 2) {
                // Add up the votes of the top two candidates, this is used to calculate the 'percentage' of votes
                const topPartyVotes =
                    allCandidates[0].primaryVotes +
                    allCandidates[1].primaryVotes

                const candidateOnePercentage = topPartyVotes
                    ? Math.round(
                          (allCandidates[0].primaryVotes / topPartyVotes) * 100,
                      )
                    : 0
                const candidateTwoPercentage = topPartyVotes
                    ? Math.round(
                          (allCandidates[1].primaryVotes / topPartyVotes) * 100,
                      )
                    : 0
                // Otherwise, just use the first two candidates
                candidateOne = {
                    ...allCandidates[0],
                    currentVotesPercentage: candidateOnePercentage,
                    incumbent: allCandidates[0].isIncumbent,
                }
                candidateTwo = {
                    ...allCandidates[1],
                    currentVotesPercentage: candidateTwoPercentage,
                    incumbent: allCandidates[1].isIncumbent,
                }
            }
        }
    }

    // If there is only one candidate, or the candidate has no votes, set candidateTwo to default "TBC" candidate
    if (!candidateTwo || candidateTwo.primaryVotes === 0) {
        candidateTwo = {
            candidateName: 'TBC',
            partyName: 'TBC',
            shortPartyName: 'TBC',
            primaryVotes: 0,
            currentVotesPercentage: 0,
            partyColor: partyColors['NONE'],
            incumbent: false,
            awaitingResults: true,
            imageUrl: '',
            partyCode: 'OTH',
            swing: null,
            isIncumbentParty: false,
        }
    }

    // If there are no candidates, or the candidate has no votes, set candidateOne to incumbent or default "TBC" candidate
    if (!candidateOne || candidateOne.primaryVotes === 0) {
        const incumbentCandidate = allCandidates.find(
            (candidate) => candidate.isIncumbent || candidate.isIncumbentParty,
        )

        candidateOne = incumbentCandidate
            ? {
                  candidateName: incumbentCandidate.candidateName,
                  partyName: incumbentCandidate.partyName,
                  shortPartyName: incumbentCandidate.shortPartyName,
                  primaryVotes: 0,
                  currentVotesPercentage: 0,
                  partyColor: getPartyColors(incumbentCandidate.partyCode),
                  incumbent: incumbentCandidate.isIncumbent,
                  awaitingResults: false,
                  imageUrl: incumbentCandidate.imageUrl,
                  partyCode: incumbentCandidate.partyCode,
                  swing: null,
                  isIncumbentParty: incumbentCandidate.isIncumbentParty,
              }
            : {
                  candidateName: 'TBC',
                  partyName: 'TBC',
                  shortPartyName: 'TBC',
                  primaryVotes: 0,
                  currentVotesPercentage: 0,
                  partyColor: partyColors['NONE'],
                  incumbent: false,
                  awaitingResults: true,
                  imageUrl: '',
                  partyCode: 'OTH',
                  swing: null,
                  isIncumbentParty: false,
              }
    }
    return [candidateOne, candidateTwo]
}

export const getCandidateTextState = (
    candidates: SeatTwoCandidatePreferred[],
    allCandidates: SeatAllCandidates[],
): string => {
    return candidates.length === 0
        ? allCandidates.filter((c) => c.primaryVotes > 0).length < 2
            ? 'Awaiting Results'
            : 'Leading Candidates'
        : 'Two Party Preferred'
}
