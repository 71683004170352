import styled from '@emotion/styled'
import { IconSpinner } from '../../../icons'
import { IconChevronRightTN } from '../../../icons/IconChevronRightTN/IconChevronRightTN'
import { breakpoint, calcRem } from '../../../__styling'

export const TheRaceWidgetContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(8),
    alignSelf: 'stretch',

    padding: calcRem(16, 0),

    [breakpoint('lg')]: {
        padding: calcRem(0),
    },
})

export const TheRaceWidgetChevron = styled(IconChevronRightTN)({})

// TheRace Widget Bar Graph Styled Components

export const TheRaceWidgetGraphContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    gap: calcRem(8),
    alignSelf: 'stretch',
    position: 'relative',
})

export const TheRaceWidgetGraphDataContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(16),
    flex: '1 0 0',

    [breakpoint('lg')]: {
        paddingTop: calcRem(8),
    },
})
