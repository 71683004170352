import {
    AllEvents,
    ConfigurationContext,
    DataLayerEventName,
    ElectionDefinition,
    NavEvent,
    queries,
} from '@news-mono/web-common'
import { useQuery } from '@tanstack/react-query'
import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
} from 'react'
import { useImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { SeatCard } from '../SeatCard/SeatCard'
import { ElectionDataFetchError, ElectionTitle } from '../components'
import { ElectionSearchInput } from '../components/ElectionSearch'
import {
    getSeatsData,
    searchSeatsOrCandidates,
    sortSeats,
    transformAreaData,
} from '../data'
import {
    TheSeatsDivider,
    TheSeatsNoResultsText,
    TheSeatsWidgetChevron,
    TheSeatsWidgetContainer,
    TheSeatsWidgetHeaderLink,
    TheSeatsWidgetSeatGrid,
} from './TheSeatsWidget.styled'
import { ElectionPredictionDisclaimer } from '../components/ElectionPredictionDisclaimer'
import { ElectionFilterContext } from '../../../contexts'

export interface SeatsWidgetProps {
    electionDefinition: ElectionDefinition
    onEvent?: (event: AllEvents) => void
    electoratePageUrl: string
    seatDisplayCount?: number
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
    imageBaseUrl?: string
}

export const TheSeatsWidget = ({
    electionDefinition,
    onEvent,
    electoratePageUrl,
    seatDisplayCount = 6,
    headingLevel,
    titleText = 'The Seats',
    imageBaseUrl,
}: SeatsWidgetProps): JSX.Element => {
    const { search } = useContext(ElectionFilterContext)
    const { value: searchValue } = search
    const config = useContext(ConfigurationContext)
    const result = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    const allSeatsData = useMemo(() => {
        if (!result.isSuccess || !result.data.electionData.data) return []
        const interknowlogyData = transformAreaData(
            result.data.electionData.data,
        )
        return getSeatsData(interknowlogyData, imageBaseUrl, 64)
    }, [imageBaseUrl, result.data?.electionData.data, result.isSuccess])

    const seatData = useMemo(() => {
        return searchValue
            ? searchSeatsOrCandidates(allSeatsData, searchValue)
            : allSeatsData
    }, [searchValue, allSeatsData])

    const sortedAndSliced = useMemo(() => {
        const localSorted = sortSeats(seatData)
        return (localSorted ? localSorted : seatData).slice(0, seatDisplayCount)
    }, [seatData, seatDisplayCount])

    // Loading State!
    if (result.isLoading) {
        return (
            <TheSeatsWidgetWrapper
                onEvent={onEvent}
                electoratePageUrl={electoratePageUrl}
                titleText={titleText}
            >
                <ElectionSearchInput
                    onEvent={onEvent}
                    electionFilter={search}
                />
                <TheSeatsWidgetSeatGrid>
                    {Array(seatDisplayCount) // Render skeletons while loading
                        .fill(0)
                        .map((_, index) => (
                            <SeatCard key={index} isLoading />
                        ))}
                </TheSeatsWidgetSeatGrid>
            </TheSeatsWidgetWrapper>
        )
    }
    // // Error State! (data has loaded, but there is no data)
    if (
        (result.isSuccess && !result.data.electionData.data) ||
        result.isError
    ) {
        return (
            <TheSeatsWidgetWrapper
                onEvent={onEvent}
                electoratePageUrl={electoratePageUrl}
                titleText={titleText}
            >
                <ElectionDataFetchError />
            </TheSeatsWidgetWrapper>
        )
    }

    // No Search Results State!
    if (searchValue && sortedAndSliced && sortedAndSliced.length <= 0) {
        return (
            <TheSeatsWidgetWrapper
                onEvent={onEvent}
                electoratePageUrl={electoratePageUrl}
                titleText={titleText}
            >
                <ElectionSearchInput
                    onEvent={onEvent}
                    electionFilter={search}
                />
                <TheSeatsNoResultsText>
                    No results for ‘{searchValue}’
                </TheSeatsNoResultsText>
                <TheSeatsDivider />
            </TheSeatsWidgetWrapper>
        )
    }

    return (
        <TheSeatsWidgetWrapper
            onEvent={onEvent}
            electoratePageUrl={electoratePageUrl}
            headingLevel={headingLevel}
            titleText={titleText}
        >
            <ElectionSearchInput onEvent={onEvent} electionFilter={search} />
            <TheSeatsWidgetSeatGrid>
                {sortedAndSliced.map((seat) => (
                    <SeatCard
                        key={seat.seatId}
                        seat={seat}
                        onEvent={onEvent}
                        electoratePageUrl={electoratePageUrl}
                    />
                ))}
            </TheSeatsWidgetSeatGrid>
        </TheSeatsWidgetWrapper>
    )
}

const TheSeatsWidgetWrapper = ({
    children,
    onEvent,
    electoratePageUrl,
    headingLevel,
    titleText,
}: PropsWithChildren<{
    onEvent?: (event: NavEvent) => void
    electoratePageUrl: string
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
}>) => {
    const impressionAvailableRef = useImpressionAvailable({
        loading: false,
        available: useCallback(() => {
            onEvent?.({
                type: DataLayerEventName.navAvailable,
                originator: 'ElectionTheSeatsWidget',
                payload: {
                    navName: 'ElectionSeats',
                },
            })
        }, [onEvent]),
    })

    const handleHeadingClick = useCallback(() => {
        onEvent?.({
            type: DataLayerEventName.navClicked,
            originator: 'ElectionTheSeatsWidget',
            payload: {
                navName: 'ElectionSeatsWidget',
                navLocation: 'OnPage',
                navText: 'The Seats',
                navLink: electoratePageUrl,
            },
        })
    }, [electoratePageUrl, onEvent])
    return (
        <TheSeatsWidgetContainer ref={impressionAvailableRef}>
            <TheSeatsWidgetHeaderLink
                to={electoratePageUrl}
                onClick={handleHeadingClick}
            >
                <ElectionTitle as={headingLevel || undefined}>
                    {titleText}
                </ElectionTitle>
                <TheSeatsWidgetChevron />
            </TheSeatsWidgetHeaderLink>
            {children}
            <ElectionPredictionDisclaimer />
        </TheSeatsWidgetContainer>
    )
}
