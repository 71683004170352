import styled from '@emotion/styled'
import {
    breakpoint,
    calcRem,
    getComponentTheme,
    TheNightlyThemeConfig,
} from '../../../__styling'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { tokens } from '@news-mono/design-tokens'

const { utility, neutral, white } = tokens.thenightly.colors.palette

export const ElectionTppEstCardContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: calcRem(24),
    width: '100%',
    height: calcRem(80),
    minWidth: calcRem(342),
    background: white,
    [breakpoint('sm')]: {
        height: calcRem(92),
    },
})

export const ElectionTppEstCardTitle = styled.p(({ theme }) => {
    const cardTheme = getComponentTheme(theme, 'electionTppEstCardTheme')

    return [
        {
            margin: 0,
            color: neutral[100],
        },
        textMixin(theme, cardTheme.title.textType),
    ]
})
interface ElectionTppEstCardTextProps {
    variant: 'percentage' | 'label'
}

export const ElectionTppEstCardText = styled.p<ElectionTppEstCardTextProps>(
    ({ theme, variant }) => {
        const cardTheme = getComponentTheme(theme, 'electionTppEstCardTheme')

        return [
            textMixin(theme, cardTheme.partyInfo.textType),
            {
                margin: 0,
                fontWeight: variant === 'percentage' ? 700 : 500,
            },
        ]
    },
)

export const ElectionTppsEstCardErrorContainer = styled.div(({ theme }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: calcRem(8),
        width: '100%',
        height: calcRem(32),
        [breakpoint('sm')]: {
            height: calcRem(36),
        },
        paddingRight: calcRem(16),
        paddingLeft: calcRem(16),
        boxSizing: 'border-box',
        backgroundColor: neutral[10],
        borderRadius: calcRem(4),
        border: `1px solid ${neutral[20]}`,
    }
})

export const ElectionTppsEstCardErrorText = styled.p<{ bold?: boolean }>(
    ({ theme, bold = false }) => {
        return [
            textMixin<TheNightlyThemeConfig>(theme, 'body-alt'),
            {
                color: utility.error.default,
                margin: 0,
                fontWeight: bold ? 700 : 400,
            },
        ]
    },
)
