import styled from '@emotion/styled'
import React from 'react'
import { getComponentTheme, breakpointSwitch } from '../../../__styling'

export interface ElectionNoteTextProps {
    children?: React.ReactNode
}

export const ElectionNoteText = ({ children }: ElectionNoteTextProps) => (
    <StyledElectionNoteText>{children}</StyledElectionNoteText>
)

// Container object, handles the background colours and the hover, & onClick!
const StyledElectionNoteText = styled('p')(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return [
        {
            textAlign: 'center',
            margin: 0,
        },
        electionTheme.text &&
            breakpointSwitch(electionTheme.note, (styles) => styles),
    ]
})
