import { PartyCode } from '@west-australian-newspapers/election-api-types'
import _ from 'lodash'
import { ElectionStateFilterOptions } from '../../../contexts'
import { electionsDebug } from '../../../__helpers/elections-debug'
import {
    ExtendedInterknowlogyData,
    ExtendedInterknowlogyParty,
} from './getInterknowlogyData'
import { filterAreasByState } from './transformResults'

export const getAllFilteredElectionParties = (
    dataFeed: ExtendedInterknowlogyData,
    filter: ElectionStateFilterOptions[number],
) => {
    const filteredAreas = filterAreasByState(dataFeed.areas, filter)
    const combinedParties = filteredAreas.flatMap((area) => area.parties)

    electionsDebug('combined area parties', combinedParties)

    // Combine all the parties in the combinedParties variable with matching partyCodes to a list of parties with only one entry per list, combining the seatCount of each party together
    const combinedPartiesMap = new Map<PartyCode, ExtendedInterknowlogyParty>()
    combinedParties.forEach((party) => {
        const partyCode = party.partyCode as PartyCode
        const current = combinedPartiesMap.get(partyCode)

        if (current) {
            current.seatsWon += party.seatsWon
            current.seatsAhead += party.seatsAhead
            current.voteCount += party.voteCount

            // Update the combined states
            if (current.combinedStates) {
                current.combinedStates = [
                    ...current.combinedStates,
                    party.state,
                ]
            } else {
                current.combinedStates = [party.state]
            }
            // Update the combined pct
            if (current.combinedPct) {
                current.combinedPct += party.votePct
            } else {
                current.combinedPct = party.votePct
            }

            // Set votePct of the average of the combinedPct
            current.votePct =
                current.combinedPct / current.combinedStates.length
        } else {
            const newParty = _.cloneDeep(party)

            newParty.combinedCode = partyCode
            newParty.combinedPartyCodes = [partyCode]
            newParty.combinedStates = [party.state]
            newParty.combinedPct = party.votePct

            if (newParty.partyCode === 'COA') {
                newParty.partyName = 'Coalition'
                newParty.shortPartyName = 'Coalition'
            }

            combinedPartiesMap.set(partyCode, newParty)
        }
    })

    electionsDebug('all parties combined', combinedPartiesMap)
    return Array.from(combinedPartiesMap.values())
}
