import {
    AllEvents,
    ConfigurationContext,
    ElectionDefinition,
    LoadedElectionDefinition,
    queries,
} from '@news-mono/web-common'
import { useQuery } from '@tanstack/react-query'
import React, { useContext, useMemo } from 'react'
import {
    ElectionDataFetchError,
    ElectionText,
    ElectionTitle,
    ElectionTitleButton,
} from '../components'
import { getElectionPartyData, transformAreaData } from '../data'
import { TheRaceRow } from './components/TheRaceRow/TheRaceRow'
import { TheRaceRowPlaceholder } from './components/TheRaceRowPlaceholder/TheRaceRowPlaceholder'
import { TheRaceWinIndicator } from './components/TheRaceWinIndicator'
import {
    TheRaceWidgetChevron,
    TheRaceWidgetContainer,
    TheRaceWidgetGraphContainer,
    TheRaceWidgetGraphDataContainer,
} from './TheRaceWidget.styled'
import { ElectionPredictionDisclaimer } from '../components/ElectionPredictionDisclaimer'

export interface TheRaceWidgetProps {
    electionDefinition: ElectionDefinition
    partiesToShow?: number
    redirectTo?: string
    onEvent: (event: AllEvents) => void
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const TheRaceWidget = ({
    electionDefinition,
    partiesToShow = 5,
    redirectTo,
    headingLevel,
}: TheRaceWidgetProps) => {
    //set up initial data if available
    const config = useContext(ConfigurationContext)
    const result = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    return (
        <TheRaceWidgetContainer>
            <ElectionTitleButton to={redirectTo}>
                <ElectionTitle as={headingLevel}>The Race</ElectionTitle>
                {redirectTo && <TheRaceWidgetChevron />}
            </ElectionTitleButton>
            <ElectionText>Seats won (+likely to win)</ElectionText>
            <TheRaceWidgetGraphContainer>
                {result.isError ? (
                    <ElectionDataFetchError />
                ) : result.isLoading ? (
                    <LoadingData partiesToShow={partiesToShow} />
                ) : (
                    <LoadedData
                        partiesToShow={partiesToShow}
                        electionDefinition={result.data}
                    />
                )}
            </TheRaceWidgetGraphContainer>
            <ElectionPredictionDisclaimer paddingTop={20}/>
        </TheRaceWidgetContainer>
    )
}

type LoadingDataProps = {
    partiesToShow: number
}

const LoadingData = ({ partiesToShow }: LoadingDataProps) => {
    return (
        <TheRaceWidgetGraphDataContainer>
            {Array(partiesToShow)
                .fill(0)
                .map((party, index) => (
                    <TheRaceRowPlaceholder key={index} />
                ))}
        </TheRaceWidgetGraphDataContainer>
    )
}

interface LoadedDataProps {
    electionDefinition: LoadedElectionDefinition
    partiesToShow: number
}
const LoadedData = ({ electionDefinition, partiesToShow }: LoadedDataProps) => {
    const partyData = useMemo(
        () =>
            electionDefinition.electionData.data
                ? getElectionPartyData({
                      dataFeed: transformAreaData(
                          electionDefinition.electionData.data,
                      ),
                      partiesToShow,
                  })
                : undefined,
        [electionDefinition.electionData.data, partiesToShow],
    )
    const totalBarLength =
        electionDefinition.electionData.config?.barTotalSeats || 100
    const seatsToWin = electionDefinition.electionData.config?.seatsToWin || 76
    const percentPerSeat = 100 / totalBarLength

    return (
        <>
            <TheRaceWinIndicator
                seatsToWin={seatsToWin}
                percentPerSeat={percentPerSeat}
            />
            <TheRaceWidgetGraphDataContainer>
                {/* Now implement each individual row! */}
                {partyData?.parties.slice(0, partiesToShow).map((party) => (
                    <TheRaceRow
                        key={party.code}
                        party={party}
                        percentPerSeat={percentPerSeat}
                        seatsToWin={seatsToWin}
                    />
                ))}
            </TheRaceWidgetGraphDataContainer>
        </>
    )
}
