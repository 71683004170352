import placeholderImg from './assets/placeholder-transparent.png'
// Handle image load errors with fallback image
export const handleImageEvent = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (event.type === 'error') {
        event.currentTarget.src = placeholderImg
        event.currentTarget.alt = 'Image not available'
    }
}

export const getInitials = (name: string): string => {
    if (name.toLowerCase() === 'tbc') {
        return ''
    }
    return name
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase()
}