/**
 * Two-party preferred (TPP) head-to-head
 * The visualisation will include both won seats and predicted seats and have a line in the centre showing the target to winning the election.
 */

import React, { useMemo } from 'react'
import { ExtendedInterknowlogyParty, getPartyColors } from '../data'
import { SeatBarGraph } from '../SeatCard/SeatCard'
import {
    SeatVoteCountContainer,
    SeatVotesContainer,
} from '../SeatCard/SeatCard.styled'
import {
    ElectionTppEstCardContentContainer,
    ElectionTppEstCardText,
    ElectionTppEstCardTitle,
    ElectionTppsEstCardErrorContainer,
    ElectionTppsEstCardErrorText,
} from './ElectionTppEstCard.styled'
import { ElectionConfig } from '@west-australian-newspapers/election-api-types'
import { electionsDebug } from '../../../__helpers/elections-debug'

interface ElectionTppEstData {
    leftPartyName?: string
    leftPartyPercentage: number
    leftPartyColor: string
    rightPartyName?: string
    rightPartyColor: string
}

interface ElectionTppEstCardProps {
    title: string
    data: ExtendedInterknowlogyParty[]
    config: ElectionConfig
}

export const ElectionTppEstCard = ({
    title,
    data,
    config,
}: ElectionTppEstCardProps) => {
    const initialData = useMemo(() => {
        if (data.length < 2) {
            electionsDebug('Not enough data to render TPP card.}', data)
            return false
        }

        let partyData: ExtendedInterknowlogyParty[] = []

        /**
         * In WA, we only want to show ALP and LIB, with ALP on the left
         * @todo we should add config item to the Election API, eg `"head2headParties": {"partyCode","partyCode"}`
         * to determine what parties to show
         */

        if (config.electionType === 'state' && config.state === 'WA') {
            partyData = data
                .filter(
                    (party) =>
                        party.partyCode === 'ALP' || party.partyCode === 'LIB',
                )
                .sort((a, b) => (a.partyCode === 'ALP' ? -1 : 1))
        } else {
            // Grab the top 2 parties from the incoming data
            // These are sorted by seats won, so the top 2 are always what we want to show
            partyData = data.splice(0, 2)
        }

        // Calculate the percentage of the left party which is used to calculate the width of the bars
        const leftPartyPercentage = Math.round(
            (100 * partyData[0].votePct) /
                (partyData[0].votePct + partyData[1].votePct),
        )
        const isNoDataState =
            partyData[0].votePct === 0 || partyData[1].votePct === 0

        // Transform the data into the format required by the component
        const tppData: ElectionTppEstData = {
            leftPartyName:
                partyData[0].shortPartyName || partyData[0].partyName,
            leftPartyPercentage: leftPartyPercentage,
            leftPartyColor: getPartyColors(partyData[0].partyCode).primary,
            rightPartyName:
                partyData[1].shortPartyName || partyData[1].partyName,
            rightPartyColor: getPartyColors(partyData[1].partyCode).primary,
        }
        return {
            tppData,
            isNoDataState,
        }
    }, [data, config])

    if (!initialData) {
        return (
            <ElectionTppEstCardContentContainer>
                <>
                    <ElectionTppEstCardTitle>{title}</ElectionTppEstCardTitle>
                    <ElectionTppsEstCardErrorContainer>
                        <ElectionTppsEstCardErrorText bold>
                            Error
                        </ElectionTppsEstCardErrorText>
                        <ElectionTppsEstCardErrorText>
                            Refresh the page or try again later.
                        </ElectionTppsEstCardErrorText>
                    </ElectionTppsEstCardErrorContainer>
                </>
            </ElectionTppEstCardContentContainer>
        )
    }

    const { tppData, isNoDataState } = initialData

    if (isNoDataState) {
        const leftColour = getPartyColors('NONE').light
        const rightColour = getPartyColors('NONE').dark
        return (
            <ElectionTppEstCardContentContainer>
                <>
                    <ElectionTppEstCardTitle>{title}</ElectionTppEstCardTitle>
                    <div>
                        <SeatVotesContainer>
                            <SeatVoteCountContainer color={leftColour}>
                                <ElectionTppEstCardText variant="percentage">
                                    0%
                                </ElectionTppEstCardText>
                                <ElectionTppEstCardText variant="label">
                                    {''}
                                </ElectionTppEstCardText>
                            </SeatVoteCountContainer>
                            <SeatVoteCountContainer color={rightColour}>
                                <ElectionTppEstCardText variant="label">
                                    {''}
                                </ElectionTppEstCardText>
                                <ElectionTppEstCardText variant="percentage">
                                    0%
                                </ElectionTppEstCardText>
                            </SeatVoteCountContainer>
                        </SeatVotesContainer>
                        <SeatBarGraph
                            candidateOneColor={leftColour}
                            candidateTwoColor={rightColour}
                            candidateOnePct={50}
                        ></SeatBarGraph>
                    </div>
                </>
            </ElectionTppEstCardContentContainer>
        )
    }

    return (
        <ElectionTppEstCardContentContainer>
            <>
                <ElectionTppEstCardTitle>{title}</ElectionTppEstCardTitle>
                <div>
                    <SeatVotesContainer>
                        <SeatVoteCountContainer color={tppData.leftPartyColor}>
                            <ElectionTppEstCardText variant="percentage">
                                {tppData.leftPartyPercentage}%
                            </ElectionTppEstCardText>
                            <ElectionTppEstCardText variant="label">
                                {tppData.leftPartyName || 'Other'}
                            </ElectionTppEstCardText>
                        </SeatVoteCountContainer>
                        <SeatVoteCountContainer color={tppData.rightPartyColor}>
                            <ElectionTppEstCardText variant="label">
                                {tppData.rightPartyName || 'Other'}
                            </ElectionTppEstCardText>
                            <ElectionTppEstCardText variant="percentage">
                                {100 - tppData.leftPartyPercentage}%
                            </ElectionTppEstCardText>
                        </SeatVoteCountContainer>
                    </SeatVotesContainer>
                    <SeatBarGraph
                        candidateOneColor={tppData.leftPartyColor}
                        candidateTwoColor={tppData.rightPartyColor}
                        candidateOnePct={tppData.leftPartyPercentage}
                    ></SeatBarGraph>
                </div>
            </>
        </ElectionTppEstCardContentContainer>
    )
}
