import { ResolveSlugV4DTO } from '@west-australian-newspapers/publication-types'
import H from 'history'
import { getAdTargetingPath } from '../advertising/ad-targeting'
import { AdTargeting } from '../advertising/add-ads'
import { GptApi } from '../advertising/gpt-api'
import { RenderTarget } from '../context-providers/render-target-context'
import { DataLoaderGlobalParams, resources } from '../data/resources'
import { PageError, RouteInfoResolverFailedEvent } from '../events/page-events'
import { diagFailResolveRoute } from '../routing/error-routes'
import {
    RouteServices,
    StaticPageRoute,
    StaticRoute,
    StaticRoutes,
} from '../routing/page-definition'
import { RouteHints } from '../routing/route-hints'
import { resolveSlug } from '../__content-api/v4/resolve-route'
import { getSiteSection, stripTrailingSlash } from './helpers'
import { routingDebug } from './routing-debug'
import { randomUUID } from 'crypto'

let staticRoutes: StaticRoutes = {}
/** matches route keys as regex */
let matchRoutes: StaticRoutes

let serverRoutes: ServerResolvedRoutes
let errorRouteInfo: StaticPageRoute
let notFoundRouteInfo: StaticPageRoute

export interface QueryString {
    [key: string]: boolean | number | string
}

export interface SsrResolverProps {
    host: string | undefined
    path: string
    query: QueryString
    state: any
    routeServices: RouteServices
}

export function registerPageRoutes<Sections extends string = string>(routes: {
    staticRoutes: StaticRoutes<Sections>
    /** matches route keys as regex */
    matchRoutes: StaticRoutes<Sections>
    serverRoutes: ServerResolvedRoutes<Sections>
    errorRouteInfo: StaticPageRoute<Sections>
    notFoundRouteInfo: StaticPageRoute<Sections>
}) {
    staticRoutes = routes.staticRoutes
    matchRoutes = routes.matchRoutes
    serverRoutes = routes.serverRoutes
    errorRouteInfo = routes.errorRouteInfo
    notFoundRouteInfo = routes.notFoundRouteInfo
}

export function getRegisteredRoutes() {
    return {
        staticRoutes,
        matchRoutes,
        serverRoutes,
        errorRouteInfo,
        notFoundRouteInfo,
    }
}

type ResolveRouteResolution =
    | StaticRouteResolution
    | MatchRouteResolution
    | ServerResolution
    | ErrorResolution
    | Promise<
          | StaticRouteResolution
          | MatchRouteResolution
          | ServerResolution
          | ErrorResolution
      >

function resolveRouteErrorResponse(props: {
    services: DataLoaderGlobalParams
    err: Error
    section: string | undefined
    path: string
    message: string
}): {
    type: 'error'
    error: PageError
    section: string | undefined
} {
    const { services, err, section, path, message } = props

    const errorId =
        typeof window !== 'undefined'
            ? window.crypto.randomUUID()
            : randomUUID()

    if (err.message === '404') {
        return {
            type: 'error',
            error: {
                errorType: '404',
                errorId,
            },
            section,
        }
    }
    services.log.error({ err, path: path }, message)

    return {
        type: 'error',
        error: {
            errorType: '500',
            errorId,
        },
        section,
    }
}

type Masthead = 'sevennews' | 'perthnow' | 'thewest'

/**
 * A map of paths that should be matched to a static page for each masthead.
 */
const slugStaticPageMap = {
    sevennews: [
        '/entertainment',
        '/politics',
        '/lifestyle',
        '/business/finance',
        '/news/world',
        '/sport',
        '/weather',
        '/technology',
        '/sunrise',
        '/the-morning-show',
        '/business/finance',
        '/entertainment/big-brother-australia',
        '/entertainment/sas-australia',
        '/entertainment/dancing-with-the-stars',
        '/entertainment/the-voice-australia',
        '/entertainment/home-and-away',
        '/best-picks',
        '/technology/meta-exposed',
    ],
    perthnow: ['/news'],
    thewest: [
        '/news/queen-elizabeth-ii/tributes',
        '/lifestyle/best-australian-yarn',
        '/lifestyle/best-australian-yarn/2022',
        '/lifestyle/best-australian-yarn/2023',
        '/features/bali-bombings-my-story',
        '/features/bikie-code',
        '/features/retire-ready',
        '/features/sports-masterclass',
        '/genwest',
        '/business/trading-up',
        '/sport/afl/mad-monday',
        '/news/190-years-of-the-west-australian',
        '/podcasts/court-in-the-act',
        /\/politics\/\w+-election-\d+/,
    ],
}

/**
 * An allow-list of search params that are relevant for slug resolving.
 */
const searchParamAllowlist = new Set([
    // Event pagination.
    'page',
    'page_size',
    // Match Center.
    'competition',
    'season',
    'match',
])

export async function resolveRoute(
    props: ResolveRouteProps,
): Promise<ResolveRouteResolution> {
    routingDebug('Resolving route: ' + props.path)
    const { hostname, services } = props
    const originalPath = stripTrailingSlash(props.path)
    const { path, section, isSectionPrepended } = getSitePathSection(
        originalPath,
        hostname,
        services,
    )

    try {
        // Resolve potential diag routes - needs to be against originalPath
        diagFailResolveRoute(originalPath)

        props.services.log.debug({ path }, `Resolving`)

        // First lets see if we have any static routes defined
        const staticRouteResult = staticRoutes[path]
        if (staticRouteResult !== undefined) {
            props.services.log.debug(
                { path },
                `Resolved statically defined route`,
            )

            if (
                slugStaticPageMap.hasOwnProperty(
                    services.config.apiCallerHeader,
                ) &&
                slugStaticPageMap[
                    services.config.apiCallerHeader as Masthead
                ].find((staticPath) =>
                    typeof staticPath === 'string'
                        ? staticPath === path
                        : staticPath.test(path),
                )
            ) {
                let resolution

                try {
                    resolution = await resolveSlug(
                        props.services,
                        path,
                        isSectionPrepended ? section : undefined,
                        hostname,
                    )
                } catch (error) {
                    return { type: 'static', key: path }
                }

                return { type: 'static', key: path, resolution: resolution }
            }

            return { type: 'static', key: path }
        }

        // Then match on routes with regex keys
        const matchRouteKey = Object.keys(matchRoutes).find((key) =>
            new RegExp(key).test(path),
        )

        if (matchRouteKey) {
            props.services.log.debug(
                { path, matchRouteKey },
                `Resolved match route`,
            )
            return { type: 'match', key: matchRouteKey, section }
        }

        const pathWithNoExtension = originalPath.replace('.amp', '')

        const searchParams = new URLSearchParams(props.search)

        // Apply search parameter allowlist.
        // Should improve caching and avoid sending params only relevant for the client.
        for (const key of searchParams.keys()) {
            if (!searchParamAllowlist.has(key)) {
                searchParams.delete(key)
            }
        }

        const slugWithParams = `${pathWithNoExtension}${
            `${searchParams}` ? `?${searchParams}` : ''
        }`

        return resolveSlug(
            props.services,
            slugWithParams,
            isSectionPrepended ? section : undefined,
            hostname,
        )
            .then<ServerResolution>((resolved) => {
                props.services.log.debug(
                    `Resolved slug ${slugWithParams}: "${resolved.type}"`,
                )

                return {
                    type: 'server',
                    resolution: resolved,
                }
            })
            .catch((err) => {
                return resolveRouteErrorResponse({
                    services,
                    err,
                    path,
                    section,
                    message: 'Failed to resolve slug',
                })
            })
    } catch (err) {
        return resolveRouteErrorResponse({
            services,
            err: err as Error,
            path,
            section,
            message: 'Unknown error during route resolution',
        })
    }
}

export interface RouteInfoParams {
    path: string
    host: string | undefined
    location: H.Location
    search: string
    failed: (error: RouteInfoResolverFailedEvent) => void
}

export const useRoute = resources.registerResource<
    RouteResolution,
    ResolveRouteProps
>(
    'route',
    (params) =>
        params.routeCache.getOrLoad(
            params.resourceType,
            params.paramsCacheKey,
            { path: params.path },
            async () => resolveRoute(params),
        ),
    ['path'],
)

export function createRouteServices<
    Resolution extends RouteResolution = RouteResolution,
>(
    services: DataLoaderGlobalParams,
    resolution: Resolution,
    location: H.Location,
    hostname: string | undefined,
    protocol: string | undefined,
    renderTarget: RenderTarget,
    gptApi: GptApi,
): RouteServices<Resolution> {
    return {
        ...services,
        gptApi,
        getAdTargeting: (
            adPageKind,
            section,
            primaryTopicOrPageId,
            ...secondaryTopics
        ) => {
            const pageId =
                typeof primaryTopicOrPageId === 'string'
                    ? primaryTopicOrPageId
                    : primaryTopicOrPageId.title
            const primaryTopic =
                typeof primaryTopicOrPageId === 'object'
                    ? primaryTopicOrPageId
                    : undefined

            const { adUnitPath, ssAdUnits, topics } = getAdTargetingPath(
                services.config,
                adPageKind,
                section,
                primaryTopic,
                ...secondaryTopics,
            )
            const adTargeting: AdTargeting = {
                pageId,
                adUnitPath,
                ssAdUnits,
                topics,
            }
            return adTargeting
        },
        resolution,
        location,
        hostname,
        protocol,
        renderTarget,
    }
}

export type ServerResolvedRoutes<Sections extends string = string> = {
    [resolvedType in ResolveSlugV4DTO['type']]: StaticRoute<
        Sections,
        ServerResolution<Extract<ResolveSlugV4DTO, { type: resolvedType }>>
    >
}

export interface ResolveRouteProps {
    path: string
    search: string
    hostname: string | undefined
    services: DataLoaderGlobalParams
}

export interface RouteInfoParams {
    failed: (error: RouteInfoResolverFailedEvent) => void
    location: H.Location
    // RouteResolver data loader needs a top level prop to match against
    path: string
    search: string
}

export interface StaticRouteResolution<
    Resolved extends ResolveSlugV4DTO = ResolveSlugV4DTO,
> {
    type: 'static'
    key: string
    resolution?: Resolved
}
export interface MatchRouteResolution {
    type: 'match'
    key: string
    section: string | undefined // undefined if a section is not present in the sectionOverrides
}

export interface ErrorResolution {
    type: 'error'
    error: PageError
    section: string | undefined // undefined if a section is not present in the sectionOverrides
}
export interface ServerResolution<
    Resolved extends ResolveSlugV4DTO = ResolveSlugV4DTO,
> {
    type: 'server'
    resolution: Resolved
}

export type RouteResolution =
    | StaticRouteResolution
    | MatchRouteResolution
    | ServerResolution
    | ErrorResolution
    | RouteHintResolution
    | LoadingRouteResolution
    | InspectRoute

/**
 * We have a number of services which 'inspect' routes, this could be for tests, or to find all static content used within the site
 *
 * This resolution can be used in those cases, and all routes should work with it
 **/
export interface InspectRoute {
    type: 'inspect'
}

/** Used to render an initial rendition of certain routes (publication, topic etc) while required data is loading */
export interface RouteHintResolution {
    type: 'route-hint'
    routeHint: RouteHints
}

/** If we are loading and no route hints available */
export interface LoadingRouteResolution {
    type: 'loading'
    section: string | undefined
}

export interface RedirectTo {
    targetUrl: string
    httpStatusCode?: 301 | 302 | 303 | 307 | 308
}

function getSitePathSection(
    path: string,
    hostname: string | undefined,
    services: DataLoaderGlobalParams,
) {
    // First try to resolve via hostname for the case sectionDomains is enabled
    const meta = services.store.getState().meta
    const { resolveType, section } = getSiteSection(
        path,
        hostname,
        meta.hostnameToSectionLookup,
        meta.sectionMeta,
    )

    if (resolveType === 'hostname') {
        return {
            isSectionPrepended: true,
            path: `/${section}${path}`,
            section: section,
        }
    }

    // Resolve via pathname
    return { isSectionPrepended: false, path, section }
}
