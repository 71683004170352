import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling'

export const StyledElectorateSwingContainer = styled('div')({
    display: 'none',
    position: 'relative',
    [breakpoint('sm')]: {
        display: 'block',
    },
})

export const ElectorateSwingPercentContainer = styled('div')({
    position: 'absolute',
    width: '100%',
    bottom: calcRem(-2),
    fontSize: calcRem(12),
    lineHeight: calcRem(12),
    fontWeight: 600,
    textAlign: 'center',
})
