import {
    CleanupTruskin,
    CookieConsentManager,
    DebugAdUnitsList,
    FeatureToggle,
    HeaderWrapper,
    OptaAflApiProvider,
    ScrollToTopOnNav,
    SevenNewsSection,
    TaboolaScriptProvider,
    seoTestArticleIds7News,
    GoogleNativeAppPrompt,
    isMobileViewport,
} from '@news-mono/component-library'
import {
    AdDefinition,
    AdEvent,
    AdState,
    BaseClientConfig,
    CookieConsentEvent,
    NavEvent,
    Product,
    RenderTargetContext,
    RouteMetaPageTypes,
    StickyContext,
    usePrevious,
} from '@news-mono/web-common'
import H from 'history'
import React from 'react'
import { BaseSevenNews } from './BaseSevenNews/BaseSevenNews'
import { CloseNavOnDesktop } from './close-nav-on-desktop'
import { StyledSite } from './Site.styled'
import { GigyaAuthenticationProvider } from 'libs/component-library/src/SevenNewsV2/newsletters/authentication/GigyaAuthenticationProvider'

export interface SiteProps {
    pageContents: React.ReactElement<any>
    pageType: RouteMetaPageTypes
    additionalPageProperties: { [key: string]: any }
    location: H.Location
    onEvent: (event: NavEvent | AdEvent | CookieConsentEvent) => void
    publicUrl: string
    hostname: string
    ads: AdState | undefined
    section: SevenNewsSection
    config: BaseClientConfig
}

export interface State {
    mobileNavExpanded: boolean
}

const skinsUnit: AdDefinition = {
    id: 'skins',
    size: 'skins',
    isSitewideAd: true,
}

const stickyFooter: AdDefinition = {
    id: 'sticky-footer',
    size: 'belowDesktopLeaderboard',
    isSitewideAd: true,
}
const headerLeaderboard: AdDefinition = {
    id: 'leaderboard-billboard-one',
    size: 'leaderboardBillboard',
    isSitewideAd: true,
}

const videoHubMrec: AdDefinition = {
    id: 'videohub-mrec-one',
    size: 'above1280Mrec',
}

const mobileVideoHubMrec: AdDefinition = {
    id: 'mobile-videohub-mrec-one',
    size: 'below768Mrec',
}

export const siteAds: AdDefinition[] = [
    skinsUnit,
    stickyFooter,
    videoHubMrec,
    mobileVideoHubMrec,
    headerLeaderboard,
]

export const Site: React.FC<SiteProps> = ({
    location,
    ads,
    section,
    onEvent,
    pageContents,
    additionalPageProperties,
    pageType,
    config,
    hostname,
}) => {
    const [mobileNavExpanded, setMobileNavExpanded] = React.useState(false)
    const previousLocation = usePrevious(location)
    const publicUrl = config.publicUrl
    const pageUrl = `${publicUrl}${location.pathname}`
    const { renderTarget } = React.useContext(RenderTargetContext)

    const isMobileBrowser = renderTarget === 'web' && isMobileViewport()

    React.useEffect(() => {
        if (location !== previousLocation && mobileNavExpanded) {
            setMobileNavExpanded(false)
        }
    }, [location, previousLocation, mobileNavExpanded])

    const isSeoTestArticle = seoTestArticleIds7News.some(
        (articleId) =>
            additionalPageProperties.publicationId?.toLowerCase() === articleId,
    )

    const siteComponentsUnderTaboolaProvider = (
        <OptaAflApiProvider location={location}>
            <StyledSite>
                <ScrollToTopOnNav />
                <CloseNavOnDesktop />
                <CleanupTruskin />
                <GigyaAuthenticationProvider>
                    <BaseSevenNews
                        additionalPageProperties={additionalPageProperties}
                        ads={isSeoTestArticle ? undefined : ads}
                        config={config}
                        location={location}
                        onEvent={onEvent}
                        pageContents={pageContents}
                        pageType={pageType}
                        pageUrl={pageUrl}
                        product={Product.SevenNews}
                        section={section}
                        hostname={hostname}
                    />
                </GigyaAuthenticationProvider>
                <FeatureToggle
                    feature="debug-ads"
                    on={() => (
                        <DebugAdUnitsList
                            ads={ads}
                            pathname={location.pathname}
                        />
                    )}
                />
            </StyledSite>
        </OptaAflApiProvider>
    )

    const isWidget = additionalPageProperties?.isWidget

    if (isWidget) {
        // Render only the core content for widgets
        return siteComponentsUnderTaboolaProvider
    }

    // Render the full site layout for non-widget pages
    return (
        <React.Fragment>
            {isMobileBrowser && (
                <FeatureToggle
                    feature="7-news-app-smart-banner-android"
                    on={() => <GoogleNativeAppPrompt />}
                />
            )}
            <HeaderWrapper section={section} product={Product.SevenNews} />
            <StickyContext.Provider>
                {!isSeoTestArticle ? (
                    <TaboolaScriptProvider>
                        {siteComponentsUnderTaboolaProvider}
                    </TaboolaScriptProvider>
                ) : (
                    siteComponentsUnderTaboolaProvider
                )}
            </StickyContext.Provider>
            <FeatureToggle
                feature="cookie-consent"
                on={() => <CookieConsentManager onEvent={onEvent} />}
            />
        </React.Fragment>
    )
}

Site.displayName = 'Site'
