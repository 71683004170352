import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../../mixins'
import { calcRem } from '../../../../../../__styling'

export const StyledLatestLiveBlogMilestonesContainer = styled.ul({
    display: 'block',
    padding: calcRem(metricsPN.spacing['L']),
    background: colorsPN.fill.brand.weak,
    borderRadius: metricsPN.radius['L'],
    marginTop: metricsPN.spacing['S'],
})

// Row Styling
export const StyledLiveBlogMilestoneRow = styled.li({
    display: 'block',
    gap: calcRem(metricsPN.spacing['2XS']),
    alignSelf: 'stretch',
    position: 'relative',
})
export const StyledLiveBlogBulletPoint = styled.div({
    display: 'block',
    width: calcRem(8),
    height: '100%',
    padding: 0,
    margin: '0 auto',
    position: 'absolute',
})

// Milestone line styling
export const StyledLiveBlogBulletMilestoneMark = styled.div({
    display: 'block',
    margin: '0 auto',
    width: calcRem(1),
    height: '100%',
    position: 'relative',

    background: colorsPN.stroke.brand.moderate,
})

export const StyledLiveBlogBulletPointContainer = styled.div<{
    isVrTest?: boolean
}>({
    display: 'block',
    position: 'absolute',
    zIndex: 1,
    width: 'fit-content',
    height: '100%',
})

// Row Content Styling
export const StyledLiveBlogRowContentContainer = styled.div<{
    isLastRow: boolean
}>(({ isLastRow, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: isLastRow ? 0 : calcRem(16),
    marginLeft: calcRem(metricsPN.spacing['S']),
    gap: calcRem(metricsPN.spacing['4XS']),
}))

export const StyledLiveBlogRowTitle = styled.h3({
    color: colorsPN.text.primary,
    margin: 0,
    ...perthNowNGNTextMixin.title['2XS'].semibold,
    fontVariantNumeric: 'lining-nums proportional-nums',
})

export const StyledLiveBlogRowTimestamp = styled.p({
    color: colorsPN.text.secondary,
    margin: 0,
    ...perthNowNGNTextMixin.label['M'].medium,
})
