import styled from '@emotion/styled'
import React from 'react'
import { WebLink } from '@news-mono/web-common'
import {
    breakpoint,
    calcRem,
    getComponentTheme,
    ThemeConfig,
    breakpointSwitch,
} from '../../../__styling'
import { ElectionWidgetTheme } from '../ElectionWidgetTheme.theme'

export interface ElectionTitleButtonProps {
    text?: string
    to?: string
    onClick?: () => void
    fireEvent?: () => void
    includeBorder?: boolean
    hideOnMobile?: boolean
    hideOnDesktop?: boolean
    children?: React.ReactNode
}

export const ElectionTitleButton = ({
    includeBorder = false,
    hideOnMobile = false,
    hideOnDesktop = false,
    fireEvent,
    to,
    onClick,
    text,
    children,
}: ElectionTitleButtonProps) => {
    const Container = to ? ElectionTitleButtonContainer : ElectionTitleContainer

    return (
        <Container
            to={to || ''}
            includeBorder={includeBorder}
            hideOnMobile={hideOnMobile}
            hideOnDesktop={hideOnDesktop}
            onClick={() => {
                if (fireEvent) {
                    fireEvent()
                }
                if (onClick) {
                    onClick()
                }
            }}
        >
            {text && (
                <StyledElectionTitleButtonText>
                    {text}
                </StyledElectionTitleButtonText>
            )}
            {children}
        </Container>
    )
}

const StyledElectionTitleButtonText = styled('p')(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return [
        electionTheme.text &&
            breakpointSwitch(electionTheme.text, (styles) => styles),
        {
            lineHeight: '150%',
            textTransform: 'uppercase',
            fontFamily: 'Inter',
            textAlign: 'center',
            margin: 0,
            fontWeight: 600,

            width: '100%',
        },
    ]
})

interface ElectionTitleButtonComponentProps {
    includeBorder: boolean
    hideOnMobile: boolean
    hideOnDesktop: boolean
}

const getStyling = (
    electionTheme: ElectionWidgetTheme<ThemeConfig>,
    {
        hideOnMobile,
        hideOnDesktop,
        includeBorder,
    }: ElectionTitleButtonComponentProps,
) => [
    {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        color: '#000',
        textDecoration: 'none',

        // Styling for hover / active / focus
        backgroundColor: electionTheme.button.title.backgroundColor,

        '&:hover, &:active, &:focus': {
            backgroundColor: electionTheme.button.title.hoverColor,
            cursor: 'pointer',
            color: '#000',
            textDecoration: 'none',
        },
    },
    includeBorder === true && {
        padding: calcRem(12, 24),
        border: `1px solid ${electionTheme.button.border}`,
        borderRadius: '8px',
    },
    hideOnMobile === true && {
        display: 'none',

        [breakpoint('lg')]: {
            display: 'flex',
        },
    },
    hideOnDesktop === true && {
        display: 'flex',

        [breakpoint('lg')]: {
            display: 'none',
        },
    },
]

// Container object, handles the background colours and the hover, & onClick!
const ElectionTitleContainer = styled('div')<ElectionTitleButtonComponentProps>(
    (props) =>
        getStyling(getComponentTheme(props.theme, 'electionTheme'), props),
)
const ElectionTitleButtonContainer = styled(
    WebLink,
)<ElectionTitleButtonComponentProps>((props) =>
    getStyling(getComponentTheme(props.theme, 'electionTheme'), props),
)
