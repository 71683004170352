import { electionsDebug } from '../../../__helpers/elections-debug'
import {
    ElectionSortFilterOptions,
    ElectionStateFilterOptions,
    sortMethods,
} from '../../../contexts'
import { SeatData } from './election-data'
import {
    ExtendedInterknowlogyArea,
    ExtendedInterknowlogyData,
    ExtendedInterknowlogyParty,
} from './getInterknowlogyData'

/** Filters the provided Parties based on the provided filter param.
 * i.e. passing in a filter of `WA` would only return areas with an `areacode` of WA.
 */
export const filterPartiesByState = (
    parties: ExtendedInterknowlogyParty[],
    filter: ElectionStateFilterOptions[number],
): ExtendedInterknowlogyParty[] => {
    return filter.displayName === 'All' || filter.displayName === ''
        ? parties
        : parties.filter(
              (party) => party.state.toLowerCase() === filter.paramName,
          )
}

export const filterAreasByState = (
    areas: ExtendedInterknowlogyArea[],
    filter: ElectionStateFilterOptions[number],
): ExtendedInterknowlogyArea[] => {
    return filter.displayName === 'All'
        ? areas
        : areas.filter(
              (area) => area.areaCode.toLowerCase() === filter.paramName,
          )
}
/** Filters the provided Seats based on the provided filter param.
 * i.e. passing in a filter of `WA` would only return areas with an `areacode` of WA.
 */
export const filterSeatsByState = (
    seats: SeatData[],
    filter: ElectionStateFilterOptions[number],
): SeatData[] => {
    return filter.displayName === 'All'
        ? seats
        : seats.filter((seat) => seat.state.toLowerCase() === filter.paramName)
}

/** Searches the provided array of seats for matches on `SeatName`, `CandidateName` or `Postcode` */
export const searchSeatsOrCandidates = (
    seats: SeatData[],
    searchTerm: string,
): SeatData[] => {
    const postcodeMatches = []
    const seatNameMatches = []
    const candidateMatches = []

    for (const seat of seats) {
        // Check postcode first.
        if (seat.postcodes.includes(parseInt(searchTerm))) {
            postcodeMatches.push(seat)
        } else if (
            // Otherwise check seatName
            seat.seatName.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
            seatNameMatches.push(seat)
        } else if (
            // Finally check if a candidate matches.
            seat.allCandidates.some((candidate) =>
                candidate.candidateName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
            )
        ) {
            candidateMatches.push(seat)
        } else if (
            //check if a tpp candidate matches
            seat.candidates.some((candidate) =>
                candidate.candidateName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
            )
        ) {
            candidateMatches.push(seat)
        }
        electionsDebug(
            'search filter states',
            postcodeMatches,
            seatNameMatches,
            candidateMatches,
        )
    }

    return [...postcodeMatches, ...seatNameMatches, ...candidateMatches]
}

/** Sorts the provided array of seats based on the provided sort method */
export const sortSeats = (
    seats: SeatData[] | undefined,
    sort?: ElectionSortFilterOptions[number],
) => {
    if (!seats) {
        return seats
    }

    // If no sort method is provided, default to latest
    if (!sort) {
        sort = sortMethods[0]
    }

    try {
        let sortMethod: ((a: SeatData, b: SeatData) => number) | undefined =
            undefined
        let shouldReverse = false

        switch (sort.paramName) {
            case 'a-z':
                sortMethod = (a: SeatData, b: SeatData) =>
                    a.seatName.localeCompare(b.seatName)
                break
            case 'z-a':
                sortMethod = (a: SeatData, b: SeatData) =>
                    b.seatName.localeCompare(a.seatName)
                break
            case 'latest':
            default:
                sortMethod = compareLastUpdated
                shouldReverse = true
                break
        }

        electionsDebug('sort method', sortMethod)
        const sortedSeats = [...seats].sort(sortMethod)
        if (shouldReverse) {
            sortedSeats.reverse()
        }
        electionsDebug('sorted seats', sortedSeats)
        return sortedSeats
    } catch (error) {
        electionsDebug('Error sorting seats', error)
        return seats
    }
}

/**
 * Compares two seats based on the lastUpdated property.
 *
 * A seat without a lastUpdated property is considered older than a seat
 * with a lastUpdated property.
 *
 * @returns {number} -1 if a is older, 0 if equal, 1 if a is newer
 *
 * @example
 * ```typescript
 * const seats = [
 *     // ...
 * ]
 * const sortedSeats = seats.sort(getLastUpdated)
 * ```
 */
export function compareLastUpdated(
    a: { lastUpdated?: unknown },
    b: { lastUpdated?: unknown },
): number {
    const [_a, _b] = [
        parseLastUpdated(a.lastUpdated),
        parseLastUpdated(b.lastUpdated),
    ]
    const diff = _a - _b
    switch (true) {
        case diff < 0:
            return -1
        case diff > 0:
            return 1
        default:
            return 0
    }
}
/**
 * Attempt to parse a value as a timestamp, and return the number of
 * milliseconds since the unix epoch.
 *
 * @param x - The value to parse.
 * @returns {number} - The timestamp of the lastUpdated property.
 *
 * @example
 * ```typescript
 * parseLastUpdated('2023-10-01T12:00:00.000Z') // 1696161600000
 * parseLastUpdated('2023-10-01T12:00:00.000+0800') // 1696132800000
 * parseLastUpdated(1696161600000) // 1696161600000
 * parseLastUpdated('invalid date') // 0
 * parseLastUpdated(null) // 0
 * ```
 */
export function parseLastUpdated(x: unknown): number {
    if (typeof x === 'string') {
        const timestamp = new Date(x).getTime()
        if (isNaN(timestamp)) {
            return 0
        }
        return timestamp
    }

    if (typeof x === 'number') {
        return x
    }

    return 0
}
