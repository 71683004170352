import React, { useEffect, useState } from 'react'
import { Sharing as TNSharing } from '../TheNightlySharing/Sharing'
import { SharingProps, Sharing as TWSharing } from '../Sharing/Sharing'
import { useProduct } from '../../__product'

export const CustomPageSharing: React.FC<Omit<SharingProps, 'url'>> = (
    props,
) => {
    const product = useProduct()
    //Get current page url to share, this will account for any query params
    const [url, setUrl] = useState('')
    useEffect(() => {
        const url = new URL(window.location.href)
        url.searchParams.delete('accessToken')
        const urlWithSelectQueries = `${url.origin}${url.pathname}${url.search}`
        setUrl(urlWithSelectQueries)
    }, [])

    return product === 'thewest' ? (
        <TWSharing url={url} {...props} />
    ) : (
        <TNSharing url={url} {...props} />
    )
}
