import React from 'react'
import { SeatAllCandidates } from '../data'
import {
    FullElectorateInnerRowContainer,
    CandidateText,
    PercentageText,
    VotesText,
    InformationGrid,
    FullElectorateIndicatorContainer,
    FullElectorateIndicator,
    FullElectorateRowContainer,
    FullElectorateHeadingText,
    FullElectorateRowDetailsContainer,
    CandidateDetailsContainer,
} from './FullElectorateRow.styled'

export interface FullElectorateCardRowProps {
    candidate: SeatAllCandidates
}

export const FullElectorateCardRow = ({
    candidate,
}: FullElectorateCardRowProps) => {
    const color = candidate.partyColor.pale20

    const percent = candidate.currentVotesPercentage

    const BarComponent = (
        <FullElectorateIndicatorContainer>
            <FullElectorateRowDetailsContainer>
                <CandidateDetailsContainer>
                    <FullElectorateHeadingText>
                        {candidate.partyName}
                    </FullElectorateHeadingText>
                    <CandidateText>{candidate.candidateName}</CandidateText>
                </CandidateDetailsContainer>
                <InformationGrid>
                    <div
                        style={{
                            gridColumn: 1,
                        }}
                    >
                        <PercentageText>
                            {`${candidate.currentVotesPercentage}%`}
                        </PercentageText>
                        <VotesText>{candidate.primaryVotes}</VotesText>
                    </div>
                    <div
                        style={{
                            gridColumn: 2,
                        }}
                    >
                        <PercentageText>
                            {candidate.swing ? `${candidate.swing}%` : '--'}
                        </PercentageText>
                    </div>
                </InformationGrid>
            </FullElectorateRowDetailsContainer>
            <FullElectorateIndicator widthPercent={percent} color={color} />
        </FullElectorateIndicatorContainer>
    )

    return (
        <FullElectorateRowContainer key={candidate.partyCode}>
            <FullElectorateInnerRowContainer>
                {BarComponent}
            </FullElectorateInnerRowContainer>
        </FullElectorateRowContainer>
    )
}
