import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, themedValue } from '../../../../../__styling'
import { StyledElectionText } from '../../../components'

interface TheRaceBarIndicatorContainerProps {
    isWinner: boolean
    partyColor: string
}

export const TheRaceBarIndicatorContainer = styled(
    'div',
)<TheRaceBarIndicatorContainerProps>(({ isWinner, partyColor }) => {
    const height = calcRem(isWinner ? 48 : 32)
    const background = isWinner
        ? partyColor
        : tokens.thenightly.colors.palette.neutral[10]

    return {
        display: 'flex',
        alignItems: 'center',
        flex: '1 0 0',

        position: 'relative',

        height,
        width: '100%',
        maxHeight: height,
        minHeight: height,

        background,
    }
})

export const TheRaceBarIndicator = styled('div')<{
    widthPercent: number
    partyColor: string
}>(({ widthPercent, partyColor }) => ({
    display: 'flex',
    height: '100%',
    width: `${Math.min(widthPercent, 100)}%`,
    background: `${partyColor}`,
    position: 'absolute',

    transition: 'width 0.5s',
}))

export const TheRacePartyRowContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    gap: calcRem(16),
    alignSelf: 'stretch',
})

export const TheRacePartyInnerRowContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 0',

    // Mobile styling

    flexDirection: 'column',
    height: 'fit-content',
    width: '100%',

    // Bigger styling

    [breakpoint('lg')]: {
        flexDirection: 'row',
        width: 'auto',
        height: 'auto',
    },
})

interface StyledRaceElectionTextProps {
    isWinner: boolean
    partyColor: string
}

export const StyledRaceElectionText = styled(
    StyledElectionText,
)<StyledRaceElectionTextProps>(({ isWinner, partyColor }) => ({
    color: isWinner
        ? partyColor
        : tokens.thenightly.colors.palette.neutral[100],

    fontWeight: isWinner ? 600 : 400,

    // Mobile styling
    placeSelf: 'start',
    width: '100%',
    height: calcRem(20),
    maxWidth: '100%',

    // Bigger styling
    [breakpoint('lg')]: {
        placeSelf: 'center',
        width: calcRem(125),
    },
}))

interface StyledRaceAmountTextProps {
    isWinner: boolean
    barPercent: number
    partyColor: string
    overlapsPrimaryBar: boolean
}

export const StyledRaceAmountText = styled(
    StyledElectionText,
)<StyledRaceAmountTextProps>(
    ({ theme, barPercent, isWinner, partyColor, overlapsPrimaryBar }) => {
        const isNearFullBar = barPercent >= 90 // Prevents an overflow of the number if the bar is almost full

        const textColor = overlapsPrimaryBar
            ? '#FFFFFF' // Text overlaps the current seat count (primary) bar
            : isWinner
            ? partyColor // Otherwise, if winning party use color.dark for the party
            : '#000000' // Otherwise, non-winning party

        return [
            {
                color: textColor,
                fontWeight: themedValue(theme, {
                    thenightly: 600,
                    thewest: 500,
                    fallback: 600,
                }),
                fontFamily: theme.fonts.sansSerif,

                position: 'absolute',
            },
            isNearFullBar
                ? // Apply padding to make sure the number is at the end of the bar
                  {
                      right: 0,
                      paddingRight: calcRem(8),
                  }
                : // Else apply it after the bar
                  {
                      left: `${barPercent}%`,
                      paddingLeft: calcRem(8),
                  },
            {
                transition: 'left 0.5s, right 0.5s',
            },
        ]
    },
)

export const StyledRaceStar = styled('img')({
    width: calcRem(24),
    height: calcRem(24),
    position: 'absolute',
    left: calcRem(16),
    top: calcRem(12),
    color: tokens.thenightly.colors.palette.neutral[0],
})
