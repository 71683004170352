import {
    AdUnitWrapper,
    ContentWrapper,
    FeatureToggle,
    SevenNewsFooterNetworkLinks,
    SevenSiteHeaderV2,
} from '@news-mono/component-library'
import { useFeature } from '@news-mono/web-common'
import H from 'history'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import version from '../../server/version'
import { SiteFullWidthAd } from '../Site.styled'
import { footerLinks } from '../footer'
import { globalNavigationV2, navigationV2 } from '../navigationV2'
import { BaseSevenNewsProps } from './BaseSevenNews'
import {
    StyledBaseGrid,
    StyledCell,
    StyledFooter,
    StyledNestedCell,
    StyledNestedGrid,
} from './BaseSevenNews.styled'

export interface Props {
    header?: JSX.Element
    content: JSX.Element
    footerAd: JSX.Element
    footerNetworkLinks: JSX.Element
    location?: H.Location
}

export const BaseSevenNewsTemplate: React.FC<Props & { isWidget?: boolean }> = (
    props,
) => {
    if (props.isWidget) {
        return <main>{props.content}</main>
    }

    return (
        <StyledBaseGrid>
            <StyledCell>
                {/* Header */}
                {props.header}

                {/* Content */}
                <StyledCell>
                    <main>{props.content}</main>
                </StyledCell>
            </StyledCell>

            {/* Footer Advertisment */}
            <StyledCell>
                <StyledNestedGrid>
                    <StyledNestedCell disablePadding className="footerad">
                        {props.footerAd}
                    </StyledNestedCell>
                </StyledNestedGrid>
            </StyledCell>
            <StyledFooter className="hide-print" id="footer">
                {/* Footer networkLinks */}
                <StyledCell>
                    <StyledNestedGrid>
                        <StyledNestedCell isTransparentBg={true}>
                            {props.footerNetworkLinks}
                        </StyledNestedCell>
                    </StyledNestedGrid>
                </StyledCell>
            </StyledFooter>
        </StyledBaseGrid>
    )
}

export const BaseSevenNewsWeb: React.FC<BaseSevenNewsProps> = ({
    section,
    additionalPageProperties,
    ads,
    pageContents,
    pageType,
    onEvent,
    location,
    config,
    hostname,
}) => {
    const isSimplePublicationPageType = pageType === 'simple-publication'

    const isV2LeaderBoardBilbordToggleEnabled = useFeature(
        '7-news-2-top-leaderboard-ad',
    )
    const is7NewsOlympics2024Enabled = useFeature('7-news-olympics-2024')

    const olympicsSubNavItem = {
        name: 'Olympics',
        link: '/sport/olympics',
        displayInSubNav: true,
    }

    navigationV2.forEach((item) => {
        if (
            is7NewsOlympics2024Enabled &&
            item.name === 'Sport' &&
            !item?.subNavLinks?.some((link) => link.name === 'Olympics')
        ) {
            item?.subNavLinks?.splice(1, 0, olympicsSubNavItem)
        }
    })

    const isWidget = additionalPageProperties?.isWidget

    return (
        <BaseSevenNewsTemplate
            location={location}
            isWidget={isWidget}
            header={
                isWidget ? undefined : (
                    <SevenSiteHeaderV2
                        advertisement={() => (
                            <SiteFullWidthAd>
                                {ads && !isSimplePublicationPageType && (
                                    <AdUnitWrapper
                                        noticePosition="none"
                                        padding={[16, 0]}
                                        adState={ads}
                                        unitId="sticky-footer"
                                        onEvent={onEvent}
                                        adType={'inline'}
                                    />
                                )}
                            </SiteFullWidthAd>
                        )}
                        navigation={navigationV2}
                        globalNavigation={globalNavigationV2}
                        onEvent={onEvent}
                        v2LeaderBoardAd={() => (
                            <SiteFullWidthAd>
                                {isV2LeaderBoardBilbordToggleEnabled && ads && (
                                    <AdUnitWrapper
                                        noticePosition="above-center"
                                        adState={ads}
                                        unitId="leaderboard-billboard-one"
                                        onEvent={onEvent}
                                        adType={'inline'}
                                    />
                                )}
                            </SiteFullWidthAd>
                        )}
                    />
                )
            }
            content={
                <PageProps
                    pageProperties={{
                        ...additionalPageProperties,
                        section,
                        hostname,
                        pageType,
                        codebase: {
                            version: version.buildNumber,
                            sha: version.sha,
                        },
                    }}
                >
                    <ContentWrapper>{pageContents}</ContentWrapper>
                </PageProps>
            }
            footerAd={
                isWidget ? (
                    <></>
                ) : (
                    <SiteFullWidthAd>
                        {ads && !isSimplePublicationPageType && (
                            <FeatureToggle
                                feature={'bonzai-truskin'}
                                on={() =>
                                    ads ? (
                                        <AdUnitWrapper
                                            noticePosition="none"
                                            adState={ads}
                                            unitId="skins"
                                            onEvent={onEvent}
                                            adType={'inline'}
                                        />
                                    ) : null
                                }
                            />
                        )}
                    </SiteFullWidthAd>
                )
            }
            footerNetworkLinks={
                isWidget ? (
                    <></>
                ) : (
                    <SevenNewsFooterNetworkLinks
                        networkLinks={footerLinks.footerCompanyLinks}
                        partnerLinks={footerLinks.footerPartnerLinks}
                        connectLinks={footerLinks.connectLinks}
                        newslettersLinks={footerLinks.newslettersLinks}
                    />
                )
            }
        />
    )
}

export function isHomePage(location: H.Location) {
    return ['/', '/sunrise', '/the-morning-show', '/spotlight'].includes(
        location.pathname,
    )
}
